import React, { useState, useEffect, useRef, useCallback } from "react";
import withRouter from "hoc/withRouter";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Button, ButtonGroup, Dropdown, Tab, Tabs } from "react-bootstrap";



// Redux Imports
import { useDispatch, useSelector } from "react-redux";
import { withReducer } from "store/withReducer";
import { EventSourceState, UrlOptionState, EnvFilterState, CommonComponentState, LanguageState, AppsState } from "store/Reducers";
import * as ActionTypes from "../../../store/Actions/ActionTypes";
import { GuideAnalyticsState } from "store/Reducers/GuideInsights/guideAnalytics";
import guideAnalyticsReducer from "store/Reducers/GuideInsights/guideAnalytics";
import * as guideAnalyticsActions from "store/Actions/index";
import { getCallExportExcel } from "utils/ApiCallActions";
import { useErrorBoundary } from 'react-error-boundary';


import qs from 'querystring';
import { CommonUtils } from "utils/CommonUtils";
import { ChartColors, RetryApi } from "utils/GlobalConstants";

// JSON Imports
import SectionTooltip from "components/SectionTooltip/SectionTooltip";
import SectionRefresh from "components/Layouts/SectionRefresh";
import CardContainer from "components/Layouts/CardContainer";
import ContainerTooltip from "components/Layouts/ContainerTooltip";


// SCSS Imports
import "./GuideAnalytics.scss";
import "components/Table/CommonTable.scss";



import Loader from "components/Loader/Loader";
import SectionHeader from "components/Layouts/SectionHeader";
import Datepicker from "components/Calender/Datepicker";
import SectionButtons from "components/Layouts/SectionButtons";
import ExportToPdf from "components/ExportToPdf/ExportToPdf";
import Segments from 'components/SegmentationFilter/Segmentation';
import EventSource from 'components/EventSource/EventSource';
import StateFilter from "components/StateFilter/StateFilter";
import Piechart from "components/Chart/nvd3Charts/Piechart";
import RadialChart from "components/Chart/apexCharts/ApexRadialChart2";
import CustomDashboardModal from "components/CommonModal/CustomDashboardModal/CustomDashboardModal";
import Maintenance from "components/Maintenance/Maintenance";
import LineChart from "components/Chart/nvd3Charts/LineChart";
import BarChart from "components/Chart/nvd3Charts/BarChart";
import CommonExpandableTable from "components/Table/CommonExpandableTable";
import EnvironmentFilter from "components/EnvironmentFilter/EnvironmentFilter";

import SectionFilters from "components/Layouts/SectionFilters";
import { InsightLabels } from "labels";
import { v4 as uuidv4 } from 'uuid';
import UserInfoSection from "components/Layouts/UserInfoSection";


// type PathParamsType = {
//     // param1: string,
// }

// // Your component own properties
// type props = RouteComponentProps<PathParamsType> & {
//     // someString: string,
// }

interface CurrentState {
    guideAnalytics: GuideAnalyticsState,
    eventSource: EventSourceState,
    urlOption: UrlOptionState,
    envFilter: EnvFilterState,
    commonComponentData: CommonComponentState,
    languageData: LanguageState,
    apps: AppsState
}

const GuideAnalytics: React.FC = (props: any) => {
    const { showBoundary } = useErrorBoundary();
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels)

    const location = useLocation();
    const navigate = useNavigate();

    const dateZone: any = new Date().toString();
    const timeZone: string = dateZone.match(/([A-Z]+[+-][0-9]+)/)[1];
    let fetched_details = useSelector((state: CurrentState) => state);

    const dispatch = useDispatch();

    let lineChartMargin = {
        top: "50",
        right: "50",
        bottom: "50",
        left: "75",
    }

    let linechart_height = 400;
    let guide_linechart_height = 320;

    const componentRef = useRef() as React.RefObject<HTMLDivElement>;
    const GuideMeMount = useRef(false);
    const ShowMeMount = useRef(false);
    const TestMeMount = useRef(false);
    const DoItForMeMount = useRef(false);
    const SurveyMount = useRef(false);
    const PBMount = useRef(false);
    const DoItForMePBMount = useRef(false);
    const tutorialGuidesMount = useRef(false);
    const SummaryMount = useRef(false);

    const [parameter, setParameter] = useState({
        paramter: "",
        // source: "",
    });

    // const [goingUp, setGoingUp] = useState(false);


    const [errorCount, setErrorCount] = useState({
        errorCount: [0, 0, 0, 0, 0, 0, 0, 0, 0],
        showMaintenance_flag: false
    });

    const [loaderBtn, setLoaderBtn] = useState({
        loader0: false,
        loader1: false,
        loader2: false,
        loader3: false,
        loader4: false,
        loader5: false,
        loader6: false,
        loader7: false,
        disabled: true,
    });

    const [tabKey, setTabKey] = useState({
        key: 1,
        chart: []
    });

    const [showMeTabKey, setShowMeTabKey] = useState({
        key: 0,
        chart: []
    });

    const [filterValues, setFilterValues] = useState({
        eventSource: "",
        guideStatus: "",
        segmentsGroup: {
            segmentsFilter: 0,
            segmentTitle: ''
        },
        envStatus: {
            envCode: '',
            value: ''
        }
    });

    const [guideAnalyticsGuideMeChart, setGuideAnalyticsGuideMeChart] = useState({
        loadGuideMeChartData: [],
        guideAnalyticsGuideMeChartDataFetched: false,
        ratioChartText: '',

        guideMeChart: {},
        guideMeChartFetched: false,
        api: '',
    });

    const [guideAnalyticsDetailGuideMeRadialChart, setGuideAnalyticsDetailGuideMeRadialChart] = useState({
        loadGuideMeRadialChartData: [],
        guideAnalyticsGuideMeRadialChartDataFetched: false,
        guideMeRadialChart: {},
        guideMeRadialChartFetched: false,
        totalGuideMeCount: 0,
        totalGuideMeCompleteCount: 0,
    });

    const [guideAnalyticsShowMeChart, setGuideAnalyticsShowMeChart] = useState({
        loadShowMeChartData: [],
        guideAnalyticsShowMeChartDataFetched: false,
        showMeDonutChartData: [],
        showMePieChartData: [],
        showMeLineChartData: [],
        showMeDonutDataAvailable: false,
        showMePieDataAvailable: false,
        showMeChart: {},
        showMeSlideshowChart: {},
        showMeVideoChart: {},
        showDonutChart: {},
        showPieChart: {},
        showMeChartFetched: false,
        showMeSlideshowChartFetched: false,
        showMeVideoChartFetched: false,
        showMeDonutChartFetched: false,
        showMePieChartFetched: false,
        api: '',
    });

    const [guideAnalyticsTestMeChart, setGuideAnalyticsTestMeChart] = useState({
        loadTestMeChartData: [],
        loadTestMeChartOptions: [],
        guideAnalyticsTestMeChartDataFetched: false,
        testMeChart: {},
        testMeChartFetched: false,
        api: '',
    });

    const [guideAnalyticsDoItForMeChart, setGuideAnalyticsDoItForMeChart] = useState({
        loadDoItForMeChartData: [],
        guideAnalyticsDoItForMeChartDataFetched: false,
        doItForMeChart: {},
        doItForMeChartFetched: false,
        api: '',
    });

    const [guideAnalyticsSurveyChart, setGuideAnalyticsSurveyChart] = useState({
        loadSurveyChartData: [],
        guideAnalyticsSurveyChartDataFetched: false,
        surveyChart: {},
        surveyChartFetched: false,
        api: '',
    });

    const [guideAnalyticsPBChart, setGuideAnalyticsPBChart] = useState({
        loadPBChartData: [],
        guideAnalyticsPBChartDataFetched: false,
        pBChart: {},
        pBChartFetched: false,
        api: '',
    });

    const [guideAnalyticsDoItForMePBChart, setGuideAnalyticsDoItForMePBChart] = useState({
        loadDoItForMePBChartData: [],
        guideAnalyticsDoItForMePBChartDataFetched: false,
        doItForMePBChart: {},
        doItForMePBChartFetched: false,
        api: '',
    });

    const [guideAnalyticsTutorialGuidesChart, setGuideAnalyticsTutorialGuidesChart] = useState({
        loadTutorialGuidesChartData: [],
        guideAnalyticsTutorialGuidesChartDataFetched: false,
        tutorialGuidesChart: {},
        tutorialGuidesChartFetched: false,
        api: '',
    });

    const [guideAnalyticsSummaryTable, setGuideAnalyticsSummaryTable] = useState({
        guideAnalyticsSummaryTableDataFetched: false,
        searchQuery: '',
        sortKey: 'live_guide_play_counts',
        filterLabels: [],
        page: 1,
        showExport: true,
        defaultSort: {
            'sortColumn_index': 1,
            'sortValue': 'desc'
        },
        // renderTaggedTable: false,
        sortValue: 'desc',
        limit: 5,
        tablePagination: {
            offset: 0,
            data: [],
            perPage: 5,
            currentPage: 0
        },
        firstLoad: true,
        hideDeleted: 0
    });


    const [request_uuid, setUuid] = useState({
        value: uuidv4()
    })

    // First Load @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@    
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])

    useEffect(() => {

        document.title = insightsLbls.docTitleGuides;

        setGuideAnalyticsGuideMeChart((prevState: any) => {
            return {
                ...prevState,
                ratioChartText: insightsLbls.playedVsCompleted
            }
        })

    }, [insightsLbls])

    useEffect(() => {
        window.scrollTo(0, 0);
        CommonUtils.LeftPanelHighlight(1, 0, 0, true, false);
        document.title = insightsLbls.docTitleGuides;

        setFilterValues((prevState: any) => {
            return {
                ...prevState,
                eventSource: fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
                guideStatus: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
                segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
                envStatus: { envCode: fetched_details?.commonComponentData?.envFilter?.envCode, value: fetched_details?.commonComponentData?.envFilter?.value }
            };
        });

    }, [insightsLbls]);

    // API Calls @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const getGuideAnalyticsGuideMeChartData = (request_uuid, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        setGuideAnalyticsGuideMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ['GUIDE_ANALYTICS_GUIDE_ME_CHART']
            }
        })


        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_GUIDE_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_GUIDEME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsShowMeChartData = (request_uuid, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        setGuideAnalyticsShowMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ['GUIDE_ANALYTICS_SHOW_ME_CHART']
            }
        })

        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_SHOW_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_SHOWME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsTestMeChartData = (request_uuid, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        setGuideAnalyticsTestMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ['GUIDE_ANALYTICS_TEST_ME_CHART']
            }
        })

        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_TEST_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_TESTME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDoItForMeChartData = (request_uuid, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        setGuideAnalyticsDoItForMeChart((prevState: any) => {
            return {
                ...prevState,
                api: ['GUIDE_ANALYTICS_DOITFOR_ME_CHART']
            }
        })

        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_DOITFOR_ME_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_DOITFORME_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsSurveyChartData = (request_uuid, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': false,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        setGuideAnalyticsSurveyChart((prevState: any) => {
            return {
                ...prevState,
                api: ['GUIDE_ANALYTICS_SURVEY_CHART']
            }
        })

        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_SURVEY_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_SURVEY_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));

    };

    const getGuideAnalyticsPBChartData = (request_uuid, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        setGuideAnalyticsPBChart((prevState: any) => {
            return {
                ...prevState,
                api: ['GUIDE_ANALYTICS_PB_CHART']
            }
        })

        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_PB_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_PB_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsDoItForMePBChartData = (request_uuid, apiErrorFlag?: string) => {
        if (process.env.REACT_APP_WESTPAC === 'false') {
            let params = {
                "app_id": fetched_details?.commonComponentData.appsData.appId,
                "time_zone": timeZone,
                "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
                "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
                "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
                "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
                "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
                'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
                'type': "org",
                "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
                'request_uuid': request_uuid
            };

            setGuideAnalyticsDoItForMePBChart((prevState: any) => {
                return {
                    ...prevState,
                    api: ['GUIDE_ANALYTICS_DOITFORME_PB_CHART']
                }
            })

            guideAnalyticsActions.getGuideAnalyticsData(
                params,
                "GUIDE_ANALYTICS_DOITFORME_PB_CHART",
                ActionTypes.SET_GUIDE_ANALYTICS_DOITFORME_PB_CHART_DATA,
                apiErrorFlag ?? ''
            ).then((res: any) => dispatch(res));
        }

    };

    const getGuideAnalyticsTutorialGuidesChartData = (request_uuid, apiErrorFlag?: string) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
            return {
                ...prevState,
                api: ['GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART']
            }
        })

        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART",
            ActionTypes.SET_GUIDE_ANALYTICS_TUTORIAL_GUIDES_CHART_DATA,
            apiErrorFlag ?? ''
        ).then((res: any) => dispatch(res));
    };

    const getGuideAnalyticsSummaryTableData = (request_uuid, apiErrorFlag?: string) => {

        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            "page_size": guideAnalyticsSummaryTable.tablePagination.perPage,
            "sort_by": guideAnalyticsSummaryTable.sortKey,
            "order": guideAnalyticsSummaryTable.sortValue,
            "page_index": guideAnalyticsSummaryTable.page,
            "query": encodeURIComponent(guideAnalyticsSummaryTable.searchQuery),
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            'all_guides': guideAnalyticsSummaryTable.hideDeleted ? false : true,
            "env_code": fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };

        guideAnalyticsActions.getGuideAnalyticsData(
            params,
            "GUIDE_ANALYTICS_SUMMARY_TABLE",
            ActionTypes.SET_GUIDE_ANALYTICS_SUMMARY_TABLE_DATA,
            apiErrorFlag ?? '',
            guideAnalyticsSummaryTable.firstLoad
        ).then((res: any) => dispatch(res));
    };

    const exportExcel = (data: any) => {
        let params = {
            "app_id": fetched_details?.commonComponentData.appsData.appId,
            "time_zone": timeZone,
            "start_date": fetched_details?.commonComponentData?.datesData.fdate + "_00:00:00",
            "end_date": fetched_details?.commonComponentData?.datesData.realTime_tdate + "_23:59:59",
            "event_source": fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
            "filter": fetched_details?.commonComponentData?.stateFilterData?.stateFilter?.toLowerCase() ?? 'published',
            "segment_group": fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
            "page_size": guideAnalyticsSummaryTable.limit,
            "sort_by": guideAnalyticsSummaryTable.sortKey,
            "order": guideAnalyticsSummaryTable.sortValue,
            "page_index": guideAnalyticsSummaryTable.page,
            "query": encodeURIComponent(guideAnalyticsSummaryTable.searchQuery),
            'exclude_weekends': fetched_details?.commonComponentData?.datesData.excludeFlag,
            'enable_real_time': fetched_details?.commonComponentData?.datesData.realTimeCheck,
            'type': "org",
            'all_guides': guideAnalyticsSummaryTable.hideDeleted ? false : true,
            'env_code': fetched_details?.commonComponentData?.envFilter?.envCode ?? '',
            'request_uuid': request_uuid
        };
        getCallExportExcel(params, 'GUIDE_ANALYTICS_SUMMARY_TABLE', data);
    }

    // Functions @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    const apiCatchError = (index: number, option: number) => {
        try {
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    errorCount: errorCount.errorCount.map((item: any, i: any) =>
                        i === index ? option : item
                    ),
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    const enableExportPdf = (loaderNumber: number, val: boolean) => {
        try {
            setLoaderBtn((prevState: any) => {
                return {
                    ...prevState,
                    ['loader' + loaderNumber]: val
                }
            })
        } catch (error) {
            showBoundary(error)
        }

    }

    const refreshSections = (optn: number, type?: string) => {
        try {
            enableExportPdf(optn, false);
            if (optn === 0) {
                setGuideAnalyticsGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsGuideMeChartDataFetched: false,
                        guideMeChartFetched: false,
                        guideMeChart: {}
                    };
                });

                setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsGuideMeRadialChartDataFetched: false,
                        guideMeRadialChartFetched: false,
                    }
                })
                getGuideAnalyticsGuideMeChartData(request_uuid.value);
            } else if (optn === 1) {
                setGuideAnalyticsShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsShowMeChartDataFetched: false,
                        showMeChartFetched: false,
                        showMeDonutChartFetched: false,
                        showMePieChartFetched: false,

                        showMeChart: {},
                        showPieChart: {},
                        showDonutChart: {}
                    };
                });

                setShowMeTabKey((prevState: any) => {
                    return {
                        ...prevState,
                        key: 0
                    }
                })
                getGuideAnalyticsShowMeChartData(request_uuid.value);
            }
            else if (optn === 2) {
                setGuideAnalyticsTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsTestMeChartDataFetched: false,
                        testMeChartFetched: false,
                        testMeChart: {}
                    };
                });
                getGuideAnalyticsTestMeChartData(request_uuid.value);
            }
            else if (optn === 3) {
                setGuideAnalyticsDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDoItForMeChartDataFetched: false,
                        doItForMeChartFetched: false,
                        doItForMeChart: {}
                    };
                });
                getGuideAnalyticsDoItForMeChartData(request_uuid.value);
            }
            else if (optn === 4) {
                setGuideAnalyticsSurveyChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSurveyChartDataFetched: false,
                        surveyChartFetched: false,
                        surveyChart: {}
                    };
                });
                getGuideAnalyticsSurveyChartData(request_uuid.value);
            }
            else if (optn === 5) {
                setGuideAnalyticsPBChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsPBChartDataFetched: false,
                        pBChartFetched: false,
                        pBChart: {}
                    };
                });

                setTabKey((prevState: any) => {
                    return {
                        ...prevState,
                        key: 1
                    };
                });
                getGuideAnalyticsPBChartData(request_uuid.value);
            }
            else if (optn === 6) {
                setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsTutorialGuidesChartDataFetched: false,
                        tutorialGuidesChartFetched: false,
                        tutorialGuidesChart: {}
                    };
                });
                getGuideAnalyticsTutorialGuidesChartData(request_uuid.value);
            }


            else if (optn === 7) {
                setGuideAnalyticsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSummaryTableDataFetched: false,

                        searchQuery: '',
                        sortKey: 'live_guide_play_counts',
                        filterLabels: [],
                        page: 1,
                        showExport: true,
                        sortValue: 'desc',
                        limit: 5,
                        tablePagination: {
                            offset: 0,
                            data: [],
                            perPage: 5,
                            currentPage: 0
                        },
                        firstLoad: true,
                        hideDeleted: 0,
                        defaultSort: {
                            'sortColumn_index': 1,
                            'sortValue': 'desc'
                        }
                    };
                });

            }
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleTableEvents = useCallback((option: string, data: any) => {
        try {
            switch (option) {
                case "routeToDetail":
                    setParameter((prevState: any) => {
                        return {
                            ...prevState,
                            paramter: data.guideID,
                        };
                    });
                    break;

                case "sortColumn":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            sortKey: data.sortKey,
                            sortValue: data.sortValue,
                            page: 1,
                            defaultSort: {
                                sortColumn_index: data.index,
                                sortValue: data.sortValue,
                            },
                            firstLoad: false
                        }
                    });

                    break;

                case "changePage":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: data + 1,
                            firstLoad: false
                        }
                    });
                    break;

                case "searchTxt":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            searchQuery: data,
                            firstLoad: false
                        }
                    });

                    break;

                case "changeEntries":
                    setGuideAnalyticsSummaryTable((prevState) => {
                        return {
                            ...prevState,
                            page: 1,
                            limit: data,
                            tablePagination: {                   // object that we want to update
                                ...prevState.tablePagination,    // keep all other key-value pairs
                                perPage: data       // update the value of specific key
                            },
                            firstLoad: false
                        }
                    });
                    break;

                case "hideDeleted":
                    setGuideAnalyticsSummaryTable((prevState: any) => {
                        return {
                            ...prevState,
                            page: 1,
                            firstLoad: false,
                            hideDeleted: data
                        }
                    });
                    break;

                case "exportExcel":
                    exportExcel(data)
                    break;
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [guideAnalyticsSummaryTable]);


    // UseEffect @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // Sidenav hightlight on scroll

    useEffect(() => {
        try {
            let queryParams = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData?.datesData.fdate,
                tdate: fetched_details?.commonComponentData?.datesData.tdate,
                event_source: fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
                state: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
                segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                env: fetched_details?.commonComponentData?.envFilter?.envCode,
                option: fetched_details?.urlOption.urlOption,
            }

            navigate({
                pathname: `/guide-insights/guide-analytics/`,
                search: decodeURIComponent(qs.stringify(queryParams))
            })

            const handleScroll = () => {
                const currentScrollY = window.scrollY;
                setTimeout(() => {
                    if (process.env.REACT_APP_WESTPAC === 'true') {

                        if (currentScrollY >= 530 && currentScrollY < 1100) {
                            CommonUtils.LeftPanelHighlight(1, 0, 4, true, false);
                            // optionParam = 'MyGuideSurvey'
                        } else if (currentScrollY >= 1100 && currentScrollY < 1650) {
                            CommonUtils.LeftPanelHighlight(1, 0, 5, true, false);
                            // optionParam = 'PushNotificationsAndBeacon''
                        } else if (currentScrollY >= 1650) {
                            CommonUtils.LeftPanelHighlight(1, 0, 6, true, false);
                            // optionParam = 'Summary'
                        } else {
                            CommonUtils.LeftPanelHighlight(1, 0, 0, true, false);
                            // optionParam = 'GuideMe'
                        }

                    }
                    else {
                        // let optionParam = '';
                        if (currentScrollY >= 500 && currentScrollY < 985) {
                            CommonUtils.LeftPanelHighlight(1, 0, 1, true, false);
                            // optionParam = 'ShowMe'
                        } else if (currentScrollY >= 985 && currentScrollY < 1550) {
                            CommonUtils.LeftPanelHighlight(1, 0, 2, true, false);
                            // optionParam = 'TestMe'
                        }
                        else if (currentScrollY >= 1550 && currentScrollY < 2110) {
                            CommonUtils.LeftPanelHighlight(1, 0, 3, true, false);
                            // optionParam = 'DoItForMe'
                        }
                        else if (currentScrollY >= 2110 && currentScrollY < 2635) {
                            CommonUtils.LeftPanelHighlight(1, 0, 4, true, false);
                            // optionParam = 'MyGuideSurvey'
                        } else if (currentScrollY >= 2635 && currentScrollY < 3160) {
                            CommonUtils.LeftPanelHighlight(1, 0, 5, true, false);
                            // optionParam = 'PushNotificationsAndBeacon'
                        } else if (currentScrollY >= 3160 && currentScrollY < 3710) {
                            CommonUtils.LeftPanelHighlight(1, 0, 6, true, false);
                            // optionParam = 'Tutorial Guides'
                        } else if (currentScrollY >= 3710) {
                            CommonUtils.LeftPanelHighlight(1, 0, 7, true, false);
                            // optionParam = 'Summary'
                        }
                        else {
                            CommonUtils.LeftPanelHighlight(1, 0, 0, true, false);
                            // optionParam = 'GuideMe'
                        }
                    }
                }, 0);
            };

            window.addEventListener("scroll", handleScroll, { passive: true });

            return () => window.removeEventListener("scroll", handleScroll);
        } catch (error) {
            showBoundary(error)
        }


    }, [window.scrollY]);

    // Scroll on sidenav click
    useEffect(() => {
        try {
            let queryParams = {
                app_id: fetched_details?.commonComponentData.appsData.appId,
                app_code: fetched_details?.commonComponentData.appsData.appCode,
                fdate: fetched_details?.commonComponentData?.datesData.fdate,
                tdate: fetched_details?.commonComponentData?.datesData.tdate,
                event_source: fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
                state: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
                segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                env: fetched_details?.commonComponentData?.envFilter?.envCode,
                option: fetched_details?.urlOption.urlOption,
            }

            navigate({
                pathname: `/guide-insights/guide-analytics/`,
                search: decodeURIComponent(qs.stringify(queryParams))
            })


            if (process.env.REACT_APP_WESTPAC === 'true') {

                if (fetched_details?.urlOption.urlOption === 'GuideMe' || fetched_details?.urlOption.urlOption === '') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 0, true, false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'Survey') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 4, true, false);
                    window.scrollTo({ top: 635, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'Push-Beacon') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 5, true, false);
                    window.scrollTo({ top: 1200, behavior: 'smooth' });

                } else {
                    // CommonUtils.LeftPanelHighlight(1, 0, 6, true, false);
                    window.scrollTo({ top: 1740, behavior: 'smooth' });

                }
            }
            else {
                if (fetched_details?.urlOption.urlOption === 'GuideMe' || fetched_details?.urlOption.urlOption === '') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 0, true, false);
                    window.scrollTo({ top: 0, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'ShowMe') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 1, true, false);
                    window.scrollTo({ top: 635, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'TestMe') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 2, true, false);
                    window.scrollTo({ top: 1120, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'DoItForMe') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 3, true, false);
                    window.scrollTo({ top: 1680, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'Survey') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 4, true, false);
                    window.scrollTo({ top: 2240, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'Push-Beacon') {
                    // CommonUtils.LeftPanelHighlight(1, 0, 5, true, false);
                    window.scrollTo({ top: 2800, behavior: 'smooth' });

                } else if (fetched_details?.urlOption.urlOption === 'Tutorial-Guides') {
                    window.scrollTo({ top: 3378, behavior: 'smooth' });
                }
                else {
                    // CommonUtils.LeftPanelHighlight(1, 0, 6, true, false);
                    window.scrollTo({ top: 3769, behavior: 'smooth' });

                }

            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.urlOption.urlOption])



    useEffect(() => {
        try {
            if (Object.keys(fetched_details.commonComponentData.datesData).length !== 0) {
                let queryParams = {
                    app_id: fetched_details.commonComponentData.datesData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details.commonComponentData.datesData.fdate,
                    tdate: fetched_details?.commonComponentData.datesData.tdate,
                    event_source: fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
                    state: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
                    segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                    env: fetched_details?.commonComponentData?.envFilter?.envCode,
                    option: '',
                }

                navigate({
                    pathname: `/guide-insights/guide-analytics/`,
                    search: decodeURIComponent(qs.stringify(queryParams))
                })

                if (fetched_details.commonComponentData.datesData.source === 'component' &&
                    fetched_details?.commonComponentData?.appsData.appCode == localStorage.getItem('switch_app_code') &&
                    fetched_details?.commonComponentData?.envFilter?.listFetched &&
                    fetched_details?.commonComponentData?.segmentsFilter?.listFetched) {
                    if (fetched_details.commonComponentData.datesData.url.includes('guide-analytics/')) {

                        const reqUUID = uuidv4();

                        let hash = 0;
                        let string = fetched_details.commonComponentData.datesData.appId +
                            fetched_details.commonComponentData.datesData.fdate +
                            fetched_details?.commonComponentData.datesData.tdate +
                            fetched_details?.commonComponentData?.stateFilterData?.stateFilter +
                            fetched_details?.commonComponentData?.envFilter?.envCode + `/guide-insights/guide-analytics/`;


                        enableExportPdf(0, false);
                        setFilterValues((prevState: any) => {
                            return {
                                ...prevState,
                                eventSource: fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
                                guideStatus: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
                                segmentsGroup: { segmentsFilter: fetched_details?.commonComponentData?.segmentsFilter, segmentTitle: fetched_details?.commonComponentData?.segmentsFilter?.segmentTitle },
                                envStatus: { envCode: fetched_details?.commonComponentData?.envFilter?.envCode, value: fetched_details?.commonComponentData?.envFilter?.value }
                            };
                        });
                        setGuideAnalyticsGuideMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsGuideMeChartDataFetched: false,
                                guideMeChartFetched: false,
                                guideMeChart: {},
                                loadGuideMeChartData: [],
                            };
                        });
                        setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsGuideMeRadialChartDataFetched: false,
                                guideMeRadialChartFetched: false,
                            }
                        })
                        getGuideAnalyticsGuideMeChartData(reqUUID);


                        enableExportPdf(1, false);
                        setGuideAnalyticsShowMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsShowMeChartDataFetched: false,
                                showMeChartFetched: false,
                                showMeDonutChartFetched: false,
                                showMePieChartFetched: false,
                                showMeChart: {},
                                showDonutChart: {},
                                showPieChart: {}

                            };
                        });
                        setShowMeTabKey((prevState: any) => {
                            return {
                                ...prevState,
                                key: 0
                            }
                        })
                        getGuideAnalyticsShowMeChartData(reqUUID);


                        enableExportPdf(2, false);
                        setGuideAnalyticsTestMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsTestMeChartDataFetched: false,
                                testMeChartFetched: false,
                                testMeChart: {}
                            };
                        });
                        getGuideAnalyticsTestMeChartData(reqUUID);

                        enableExportPdf(3, false);
                        setGuideAnalyticsDoItForMeChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsDoItForMeChartDataFetched: false,
                                doItForMeChartFetched: false,
                                doItForMeChart: {}
                            };
                        });
                        getGuideAnalyticsDoItForMeChartData(reqUUID);

                        enableExportPdf(4, false);
                        setGuideAnalyticsSurveyChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsSurveyChartDataFetched: false,
                                surveyChartFetched: false,
                                surveyChart: {}
                            };
                        });
                        getGuideAnalyticsSurveyChartData(reqUUID);


                        enableExportPdf(5, false);
                        setGuideAnalyticsPBChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsPBChartDataFetched: false,
                                pBChartFetched: false,
                                pBChart: {}
                            };
                        });
                        // setGuideAnalyticsDoItForMePBChart((prevState: any) => {
                        //     return {
                        //         ...prevState,
                        //         guideAnalyticsDoItForMePBChartDataFetched: false,
                        //         doItForMePBChart: {},
                        //         doItForMePBChartFetched: false,
                        //     };
                        // });
                        setTabKey((prevState: any) => {
                            return {
                                ...prevState,
                                key: 1
                            };
                        });
                        getGuideAnalyticsPBChartData(reqUUID);
                        // getGuideAnalyticsDoItForMePBChartData();
                        enableExportPdf(6, false);
                        setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsTutorialGuidesChartDataFetched: false,
                                tutorialGuidesChartFetched: false,
                                tutorialGuidesChart: {}
                            };
                        });
                        getGuideAnalyticsTutorialGuidesChartData(reqUUID);


                        enableExportPdf(7, false);
                        setGuideAnalyticsSummaryTable((prevState: any) => {
                            return {
                                ...prevState,
                                guideAnalyticsSummaryTableDataFetched: false,
                                searchQuery: '',
                                sortKey: 'live_guide_play_counts',
                                filterLabels: [],
                                page: 1,
                                showExport: true,
                                defaultSort: {
                                    'sortColumn_index': 1,
                                    'sortValue': 'desc'
                                },
                                sortValue: 'desc',
                                limit: 5,
                                tablePagination: {
                                    offset: 0,
                                    data: [],
                                    perPage: 5,
                                    currentPage: 0
                                },
                                firstLoad: true,
                                hideDeleted: 0
                            };
                        });

                        setUuid((prevState: any) => {
                            return {
                                ...prevState,
                                value: reqUUID
                            }
                        })
                    }
                }
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [fetched_details?.commonComponentData.datesData,
    fetched_details?.commonComponentData?.eventSource?.eventSourceGuides,
    fetched_details.commonComponentData?.stateFilterData,
    fetched_details?.commonComponentData?.segmentsFilter?.listFetched,
    fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
    fetched_details?.commonComponentData?.envFilter,
    ])

    // GuideMe
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData?.requestUUID === request_uuid.value
            ) {

                GuideMeMount.current = true;
                let guideMeChartData: any = [];

                let totalGuideMeCount: any = 0;
                let totalGuideMeCompleteCount: any = 0;

                let guideMeRadialChartOptions: any = [];
                let totalGuideMePlayedCompleteRatio: any = 0;

                guideMeChartData = fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData.data.graphData;

                for (let i = 0; i < guideMeChartData.length; i++) {
                    totalGuideMeCount += guideMeChartData[i].counts.guide_play;
                    totalGuideMeCompleteCount += guideMeChartData[i].counts.guide_complete;
                }

                if (totalGuideMeCount !== 0) {
                    totalGuideMePlayedCompleteRatio = totalGuideMeCompleteCount / totalGuideMeCount * 100;
                } else {
                    totalGuideMePlayedCompleteRatio = 0;
                }

                totalGuideMeCount = totalGuideMeCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                totalGuideMeCompleteCount = totalGuideMeCompleteCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

                guideMeRadialChartOptions = [
                    {
                        key: "Progress",
                        values: totalGuideMePlayedCompleteRatio,
                        color: ChartColors.color1,
                        gradientColor: ChartColors.color2,
                    },
                ];

                setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideMeRadialChartData: [...guideMeRadialChartOptions],
                        guideAnalyticsGuideMeRadialChartDataFetched: true,
                        totalGuideMeCount: totalGuideMeCount,
                        totalGuideMeCompleteCount: totalGuideMeCompleteCount,
                    };
                });

                setGuideAnalyticsGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadGuideMeChartData: [...guideMeChartData],

                        guideAnalyticsGuideMeChartDataFetched: true,
                    };
                });
                apiCatchError(0, 0);
                enableExportPdf(0, true);

            } else if (fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData?.result === 'retry') {
                getGuideAnalyticsGuideMeChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData?.result === "error"
            ) {
                setGuideAnalyticsGuideMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsGuideMeChartDataFetched: true,
                    };
                });
                setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsGuideMeRadialChartDataFetched: true,
                    };
                });
                apiCatchError(0, 1);
                enableExportPdf(0, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData, request_uuid]);

    // ShowMe
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsShowMeChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsShowMeChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsShowMeChartData?.requestUUID === request_uuid.value

            ) {
                ShowMeMount.current = true;
                let showMeChartData: any = [];
                showMeChartData = fetched_details?.guideAnalytics?.guideAnalyticsShowMeChartData.data.graphData;
                showMeChartData = CommonUtils.dateSeparator(showMeChartData);

                renderDonutChart(showMeChartData);
                renderPieChart(showMeChartData);
                setGuideAnalyticsShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsShowMeChartDataFetched: true,
                        loadShowMeChartData: [...showMeChartData]
                    };
                });

                apiCatchError(1, 0);
                enableExportPdf(1, true)

            } else if (fetched_details?.guideAnalytics?.guideAnalyticsShowMeChartData?.result === 'retry') {
                getGuideAnalyticsShowMeChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsShowMeChartData?.result === "error"
            ) {
                setGuideAnalyticsShowMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsShowMeChartDataFetched: true,
                    };
                });
                apiCatchError(1, 1);
                enableExportPdf(1, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.guideAnalytics?.guideAnalyticsShowMeChartData, request_uuid]);

    // TestMe
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData?.requestUUID === request_uuid.value

            ) {
                TestMeMount.current = true;
                let testMeChartData: any = [];
                testMeChartData = fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData.data.graphData;

                setGuideAnalyticsTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTestMeChartData: [...testMeChartData],
                        guideAnalyticsTestMeChartDataFetched: true,
                    };
                });
                apiCatchError(2, 0);
                enableExportPdf(2, true)

            } else if (fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData?.result === 'retry') {
                getGuideAnalyticsTestMeChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData?.result === "error"
            ) {
                setGuideAnalyticsTestMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsTestMeChartDataFetched: true,
                    };
                });
                apiCatchError(2, 1);
                enableExportPdf(2, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData, request_uuid]);

    // DoItForMe
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.requestUUID === request_uuid.value

            ) {
                DoItForMeMount.current = true;
                let doItForMeChartData: any = [];
                doItForMeChartData = fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData.data.graphData;


                setGuideAnalyticsDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadDoItForMeChartData: [...doItForMeChartData],
                        guideAnalyticsDoItForMeChartDataFetched: true,
                    };
                });
                apiCatchError(3, 0);
                enableExportPdf(3, true);
            } else if (fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.result === 'retry') {
                getGuideAnalyticsDoItForMeChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.result === "error"
            ) {
                setGuideAnalyticsDoItForMeChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDoItForMeChartDataFetched: true,
                    };
                });
                apiCatchError(3, 1);
                enableExportPdf(3, false)
            }

        } catch (error) {
            showBoundary(error)
        }
        if (
            fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.data !== undefined &&
            fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.result === "success" &&
            fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.requestUUID === request_uuid.value

        ) {
            DoItForMeMount.current = true;
            let doItForMeChartData: any = [];
            doItForMeChartData = fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData.data.graphData;


            setGuideAnalyticsDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    loadDoItForMeChartData: [...doItForMeChartData],
                    guideAnalyticsDoItForMeChartDataFetched: true,
                };
            });
            apiCatchError(3, 0);
            enableExportPdf(3, true);
        } else if (fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.result === 'retry') {
            getGuideAnalyticsDoItForMeChartData(request_uuid.value, 'retry');
        } else if (
            fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.result === "error"
        ) {
            setGuideAnalyticsDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    guideAnalyticsDoItForMeChartDataFetched: true,
                };
            });
            apiCatchError(3, 1);
            enableExportPdf(3, false)
        }


    }, [fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData, request_uuid]);

    // Survey
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData?.requestUUID === request_uuid.value

            ) {
                SurveyMount.current = true;
                let surveyChartData: any = [];
                surveyChartData = fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData.data.graphData;
                setGuideAnalyticsSurveyChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadSurveyChartData: [...surveyChartData],
                        guideAnalyticsSurveyChartDataFetched: true,
                    };
                });
                apiCatchError(4, 0);
                enableExportPdf(4, true)

            } else if (fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData?.result === 'retry') {
                getGuideAnalyticsSurveyChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData?.result === "error"
            ) {
                setGuideAnalyticsSurveyChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSurveyChartDataFetched: true,
                    };
                });
                apiCatchError(4, 1);
                enableExportPdf(4, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData, request_uuid]);

    // Push-Notifications
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.requestUUID === request_uuid.value

            ) {
                PBMount.current = true;
                let pBChartData: any = [];

                pBChartData = fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.data?.graphData;

                setGuideAnalyticsPBChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadPBChartData: [...pBChartData],
                        guideAnalyticsPBChartDataFetched: true,
                    };
                });
                apiCatchError(5, 0);
                enableExportPdf(5, true)

            } else if (fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.result === 'retry') {
                getGuideAnalyticsPBChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.result === "error"
            ) {
                setGuideAnalyticsPBChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsPBChartDataFetched: true,
                    };
                });
                apiCatchError(5, 1);
                enableExportPdf(5, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.guideAnalytics?.guideAnalyticsPBChartData, request_uuid]);

    // DoItForMe Push-Notifications
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData?.requestUUID === request_uuid.value

            ) {
                DoItForMePBMount.current = true;
                let doItForMePBChartData: any = [];

                doItForMePBChartData = fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData?.data?.graphData;

                setGuideAnalyticsDoItForMePBChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadDoItForMePBChartData: [...doItForMePBChartData],
                        guideAnalyticsDoItForMePBChartDataFetched: true,
                    };
                });
                apiCatchError(7, 0);
                enableExportPdf(5, true)

            } else if (fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData?.result === 'retry') {
                getGuideAnalyticsDoItForMePBChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData?.result === "error"
            ) {
                setGuideAnalyticsDoItForMePBChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDoItForMePBChartDataFetched: true,
                    };
                });
                apiCatchError(7, 1);
                enableExportPdf(5, false)
            }
        } catch (error) {
            showBoundary(error)
        }


    }, [fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData, request_uuid]);

    //TutorialGuide Notification    
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.requestUUID === request_uuid.value

            ) {
                tutorialGuidesMount.current = true;
                let TutorialGuidesChartData: any = [];
                TutorialGuidesChartData = fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData.data.graphData;
                setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        loadTutorialGuidesChartData: [...TutorialGuidesChartData],
                        guideAnalyticsTutorialGuidesChartDataFetched: true,
                    };
                });
                apiCatchError(8, 0);
                enableExportPdf(7, true)

            } else if (fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.result === 'retry') {
                getGuideAnalyticsTutorialGuidesChartData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.result === "error"
            ) {
                setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsTutorialGuidesChartDataFetched: true,
                    };
                });
                apiCatchError(8, 1);
                enableExportPdf(7, false)
            }
        } catch (error) {
            showBoundary(error)
        }
        if (
            fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.data !== undefined &&
            fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.result === "success" &&
            fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.requestUUID === request_uuid.value

        ) {
            tutorialGuidesMount.current = true;
            let TutorialGuidesChartData: any = [];
            TutorialGuidesChartData = fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData.data.graphData;
            setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                return {
                    ...prevState,
                    loadTutorialGuidesChartData: [...TutorialGuidesChartData],
                    guideAnalyticsTutorialGuidesChartDataFetched: true,
                };
            });
            apiCatchError(8, 0);
            enableExportPdf(7, true)

        } else if (fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.result === 'retry') {
            getGuideAnalyticsTutorialGuidesChartData(request_uuid.value, 'retry');
        } else if (
            fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData?.result === "error"
        ) {
            setGuideAnalyticsTutorialGuidesChart((prevState: any) => {
                return {
                    ...prevState,
                    guideAnalyticsTutorialGuidesChartDataFetched: true,
                };
            });
            apiCatchError(8, 1);
            enableExportPdf(7, false)
        }

    }, [fetched_details?.guideAnalytics?.guideAnalyticsTutorialGuidesChartData, request_uuid]);

    // Summary Table
    useEffect(() => {
        try {
            if (
                fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData?.data !== undefined &&
                fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData?.result === "success" &&
                fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData?.requestUUID === request_uuid.value

            ) {
                SummaryMount.current = true;
                setGuideAnalyticsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(6, 0);
                enableExportPdf(6, true);
            } else if (fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData?.result === 'retry') {
                getGuideAnalyticsSummaryTableData(request_uuid.value, 'retry');
            } else if (
                fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData?.result === "error"
            ) {
                setGuideAnalyticsSummaryTable((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsSummaryTableDataFetched: true,
                    };
                });
                apiCatchError(6, 1);
                enableExportPdf(6, false);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData, request_uuid]);

    // Call summary table api on table related actions changes
    useEffect(() => {
        try {
            // fetched_details?.commonComponentData?.datesData.dateSelection = 15 (Custom Date option)
            if ((fetched_details?.commonComponentData?.datesData.url?.includes('guide-analytics/'))) {
                enableExportPdf(6, false);
                getGuideAnalyticsSummaryTableData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [
        guideAnalyticsSummaryTable.sortKey,
        guideAnalyticsSummaryTable.sortValue,
        guideAnalyticsSummaryTable.defaultSort,
        guideAnalyticsSummaryTable.page,
        guideAnalyticsSummaryTable.searchQuery,
        guideAnalyticsSummaryTable.limit,
        guideAnalyticsSummaryTable.hideDeleted
    ]);

    // Export PDF Btn
    useEffect(() => {
        try {
            if (loaderBtn.loader0 && loaderBtn.loader1 && loaderBtn.loader2 && loaderBtn.loader3 && loaderBtn.loader4 && loaderBtn.loader5 && loaderBtn.loader6 && loaderBtn.loader7) {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: false,
                    };
                });
            }
            else {
                setLoaderBtn((prevState: any) => {
                    return {
                        ...prevState,
                        disabled: true,
                    };
                });
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [loaderBtn.loader0, loaderBtn.loader1, loaderBtn.loader2, loaderBtn.loader3, loaderBtn.loader4, loaderBtn.loader5, loaderBtn.loader6, loaderBtn.loader7])

    // Detail Navigation
    useEffect(() => {
        try {
            if (parameter.paramter !== '') {
                let queryParams = {
                    app_id: fetched_details?.commonComponentData.appsData.appId,
                    app_code: fetched_details?.commonComponentData.appsData.appCode,
                    fdate: fetched_details?.commonComponentData?.datesData.fdate,
                    tdate: fetched_details?.commonComponentData?.datesData.tdate,
                    state: fetched_details?.commonComponentData?.stateFilterData?.stateFilter,
                    segment: fetched_details?.commonComponentData?.segmentsFilter?.segmentsFilter,
                    env: fetched_details?.commonComponentData?.envFilter?.envCode,
                }

                navigate({
                    pathname: "/guide-insights/guide-analytics-detail/" + parameter.paramter + '/',
                    search: qs.stringify(queryParams),
                });
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [parameter])


    useEffect(() => {
        try {
            const result = errorCount.errorCount.every((val) => val === 1);
            setErrorCount((prevState: any) => {
                return {
                    ...prevState,
                    showMaintenance_flag: result
                }
            })
        } catch (error) {
            showBoundary(error)
        }


    }, [errorCount.errorCount])



    // Chart Renders and Callbacks @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    // const renderLineChart = (data: any) => {
    //     setGuideAnalyticsShowMeChart((prevState: any) => {
    //         return {
    //             ...prevState,
    //             loadShowMeChartData: [...data],
    //         };
    //     });
    // }

    const renderDonutChart = (data: any) => {
        try {
            let total = 0
            for (let itr1 = 0; itr1 < data.length; itr1++) {
                total += + data[itr1].counts.guided_showme_play + data[itr1].counts.guide_video_play + data[itr1].counts.guide_gif_play + data[itr1].counts.guide_slideshow_play;
                if (total) break;
            }

            let donutDataAvailable: boolean = false;

            total === 0 ? (donutDataAvailable = false) : (donutDataAvailable = true)
            setGuideAnalyticsShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMeDonutChartData: [...data],
                    showMeDonutDataAvailable: donutDataAvailable,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }

    const renderPieChart = (data: any) => {
        try {
            let total = 0;

            for (let itr1 = 0; itr1 < data.length; itr1++) {
                total += data[itr1].counts.guide_video_download + data[itr1].counts.guide_gif_download + data[itr1].counts.guide_slideshow_download + data[itr1].counts.guide_pdf_download + data[itr1].counts.guide_docx_download + data[itr1].counts.guide_html_download;
                if (total) break;
            }

            let pieDataAvailable: boolean = false;

            total === 0 ? (pieDataAvailable = false) : (pieDataAvailable = true)

            setGuideAnalyticsShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showMePieChartData: [...data],
                    showMePieDataAvailable: pieDataAvailable,
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    }



    const LineChartGuideMe = () => {
        try {
            setGuideAnalyticsGuideMeChart((prevState: any) => {
                return {
                    ...prevState,
                    guideMeChart: <LineChart
                        chartEntities={['guide_play', 'guide_unique_play', 'guide_complete']}
                        optionEntities={[
                            {
                                key: insightsLbls.totalPlayed,
                                associatedDataPoint: 'guide_play',
                                color: ChartColors.color1, area: true
                            },
                            {
                                key: insightsLbls.uniquePlayed,
                                associatedDataPoint: 'guide_unique_play',
                                color: ChartColors.color2, area: true
                            },
                            {
                                key: insightsLbls.completed,
                                associatedDataPoint: 'guide_complete',
                                color: ChartColors.color3, area: true
                            }
                        ]}
                        chartData={fetched_details?.guideAnalytics?.guideAnalyticsGuideMeChartData.data.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="guideAnalytics_guideme">
                    </LineChart>,
                    guideMeChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    };

    const RadialChartGuideMe = () => {
        try {
            setGuideAnalyticsDetailGuideMeRadialChart((prevState: any) => {
                return {
                    ...prevState,
                    guideMeRadialChart: <RadialChart
                        chartData={guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData}
                        width={280}
                        height={180}
                        className={"margin-0-auto"}
                    ></RadialChart>,
                    guideMeRadialChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    };


    const DonutChartShowMe = () => {
        try {
            setGuideAnalyticsShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showDonutChart: <Piechart
                        chartData={guideAnalyticsShowMeChart.showMeDonutChartData}
                        chartEntities={['guided_showme_play', 'guide_slideshow_play', 'guide_video_play', 'guide_gif_play']}
                        optionEntities={[
                            {
                                key: insightsLbls.showMe,
                                associatedDataPoint: 'guided_showme_play',
                                color: ChartColors.color1
                            },
                            {
                                key: insightsLbls.slideshow,
                                associatedDataPoint: 'guide_slideshow_play',
                                color: ChartColors.color2
                            },
                            {
                                key: insightsLbls.video,
                                associatedDataPoint: 'guide_video_play',
                                color: ChartColors.color3
                            },
                            {
                                key: insightsLbls.gif,
                                associatedDataPoint: 'guide_gif_play',
                                color: ChartColors.color4
                            }
                        ]}
                        valueFormat={'value'}
                        height={250}
                        width={250}
                        showLegends={true}
                        chartType={'donut'}
                        labelType={'percent'}
                        aggRequired={true}>
                    </Piechart>,
                    showMeDonutChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    };

    const PieChartShowMe = () => {
        try {
            setGuideAnalyticsShowMeChart((prevState: any) => {
                return {
                    ...prevState,
                    showPieChart: <Piechart
                        chartData={guideAnalyticsShowMeChart.showMePieChartData}
                        chartEntities={['guide_slideshow_download', 'guide_video_download', 'guide_gif_download', 'guide_pdf_download', 'guide_docx_download', 'guide_html_download']}
                        optionEntities={[
                            {
                                key: insightsLbls.downloadPptx,
                                associatedDataPoint: 'guide_slideshow_download',
                                color: ChartColors.darkturquoise
                            },
                            {
                                key: insightsLbls.video,
                                associatedDataPoint: 'guide_video_download',
                                color: ChartColors.brightred
                            },
                            {
                                key: insightsLbls.gif,
                                associatedDataPoint: 'guide_gif_download',
                                color: ChartColors.mediumpurple
                            },
                            {
                                key: insightsLbls.pdf,
                                associatedDataPoint: 'guide_pdf_download',
                                color: ChartColors.lightgreen
                            },
                            {
                                key: insightsLbls.docx,
                                associatedDataPoint: 'guide_docx_download',
                                color: ChartColors.yellowsandybrown
                            },
                            {
                                key: insightsLbls.html,
                                associatedDataPoint: 'guide_html_download',
                                color: ChartColors.orangesandybrown
                            },
                        ]}
                        valueFormat={'value'}
                        height={250}
                        width={250}
                        showLegends={true}
                        chartType={'pie'}
                        labelType={'percent'}
                        aggRequired={true}>
                    </Piechart>,
                    showMePieChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    };

    const BarChartTestMe = () => {
        try {
            setGuideAnalyticsTestMeChart((prevState: any) => {
                return {
                    ...prevState,
                    testMeChart: <BarChart
                        chartEntities={['test_passed', 'test_failed']}
                        optionEntities={[
                            {
                                key: insightsLbls.pass,
                                color: ChartColors.lightgreen,
                                associatedDataPoint: 'test_passed',
                                area: true
                            },
                            {
                                key: insightsLbls.fail,
                                color: ChartColors.brightred,
                                associatedDataPoint: 'test_failed',
                                area: true
                            }
                        ]}
                        chartData={fetched_details?.guideAnalytics?.guideAnalyticsTestMeChartData.data.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="guideAnalytics_testme"
                        parseIntFlag={true}>
                    </BarChart>,
                    testMeChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    };

    const LineChartDoItForMe = () => {
        try {
            setGuideAnalyticsDoItForMeChart((prevState: any) => {
                return {
                    ...prevState,
                    doItForMeChart: <LineChart
                        chartEntities={['do_it_for_me_play', 'do_it_for_me_unique_play', 'do_it_for_me_complete']}
                        optionEntities={
                            [
                                {
                                    key: insightsLbls.totalPlayed,
                                    color: ChartColors.color1,
                                    associatedDataPoint: "do_it_for_me_play",
                                    area: true
                                },
                                {
                                    key: insightsLbls.uniquePlayed,
                                    color: ChartColors.color2,
                                    associatedDataPoint: "do_it_for_me_unique_play",
                                    area: true
                                },
                                {
                                    key: insightsLbls.completed,
                                    color: ChartColors.color3,
                                    associatedDataPoint: "do_it_for_me_complete",
                                    area: true
                                }
                            ]}
                        chartData={fetched_details?.guideAnalytics?.guideAnalyticsDoItForMeChartData?.data.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="guideAnalytics_showme">
                    </LineChart>,
                    doItForMeChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }


    };

    const LineChartSurvey = () => {
        try {
            setGuideAnalyticsSurveyChart((prevState: any) => {
                return {
                    ...prevState,
                    surveyChart: < LineChart
                        chartEntities={['guideSurveyResponse', 'exitSurveyResponse', 'guideStepSurveyResponse']}
                        optionEntities={
                            [
                                {
                                    key: 'Guide Survey',
                                    color: ChartColors.color1,
                                    associatedDataPoint: "guideSurveyResponse",
                                    area: true
                                },
                                {
                                    key: 'Exit Guide Survey',
                                    color: ChartColors.color2,
                                    associatedDataPoint: "exitSurveyResponse",
                                    area: true
                                },
                                {
                                    key: 'Guide Step Survey',
                                    color: ChartColors.color3,
                                    associatedDataPoint: "guideStepSurveyResponse",
                                    area: true
                                }
                            ]}
                        chartData={fetched_details?.guideAnalytics?.guideAnalyticsSurveyChartData?.data.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="guideAnalytics_survey" >
                    </LineChart>,
                    surveyChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    };

    const LineChartPB = () => {
        try {
            setGuideAnalyticsPBChart((prevState: any) => {
                return {
                    ...prevState,
                    pBChart: <LineChart
                        chartEntities={['beaconShown', 'pushNotificationShown']}
                        optionEntities={[
                            {
                                key: insightsLbls.beacons,
                                color: ChartColors.color1,
                                associatedDataPoint: "beaconShown",
                                area: false
                            },
                            {
                                key: insightsLbls.pushNotifications,
                                color: ChartColors.color2,
                                associatedDataPoint: "pushNotificationShown",
                                area: false
                            }
                        ]}
                        chartData={fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.data?.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="guideAnalytics_pb">
                    </LineChart>,
                    pBChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }
        setGuideAnalyticsPBChart((prevState: any) => {
            return {
                ...prevState,
                pBChart: <LineChart
                    chartEntities={['beaconShown', 'pushNotificationShown']}
                    optionEntities={[
                        {
                            key: insightsLbls.beacons,
                            color: ChartColors.color1, area: false
                        },
                        {
                            key: insightsLbls.pushNotifications,
                            color: ChartColors.color2, area: false
                        }
                    ]}
                    chartData={fetched_details?.guideAnalytics?.guideAnalyticsPBChartData?.data?.graphData}
                    value_type={"count"}
                    height={linechart_height}
                    margin={lineChartMargin}
                    yAxisLabel={insightsLbls.yAxisLabelCount}
                    refId="guideAnalytics_pb">
                </LineChart>,
                pBChartFetched: true,
            };
        });
        return (
            null
        );
    };



    const LineChartDoItForMePB = () => {
        try {
            setGuideAnalyticsDoItForMePBChart((prevState: any) => {
                return {
                    ...prevState,
                    doItForMePBChart: <LineChart
                        chartEntities={['beaconShown', 'pushNotificationShown']}
                        optionEntities={[
                            {
                                key: insightsLbls.beacons,
                                color: ChartColors.color1,
                                associatedDataPoint: "beaconShown",
                                area: false
                            },
                            {
                                key: insightsLbls.pushNotifications,
                                color: ChartColors.color2,
                                associatedDataPoint: "pushNotificationShown",
                                area: false
                            }
                        ]}
                        chartData={fetched_details?.guideAnalytics?.guideAnalyticsDoItForMePBChartData?.data?.graphData}
                        value_type={"count"}
                        height={linechart_height}
                        margin={lineChartMargin}
                        yAxisLabel={insightsLbls.yAxisLabelCount}
                        refId="guideAnalytics_pb">
                    </LineChart>,
                    doItForMePBChartFetched: true,
                };
            });
            return (
                null
            );
        } catch (error) {
            showBoundary(error)
        }

    };

    const handleSwitch = (e: any) => {
        try {
            setTabKey((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });

            if (e == 1) {
                setGuideAnalyticsPBChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsPBChartDataFetched: false,
                        pBChartFetched: false,
                        pBChart: {}
                    };
                });
                getGuideAnalyticsPBChartData(request_uuid.value);
            }
            else if (e == 2) {
                setGuideAnalyticsDoItForMePBChart((prevState: any) => {
                    return {
                        ...prevState,
                        guideAnalyticsDoItForMePBChartDataFetched: false,
                        doItForMePBChart: {},
                        doItForMePBChartFetched: false,
                    };
                });
                getGuideAnalyticsDoItForMePBChartData(request_uuid.value);
            }
        } catch (error) {
            showBoundary(error)
        }

    };


    const handleShowMeSwitch = (e: any) => {
        try {
            setShowMeTabKey((prevState: any) => {
                return {
                    ...prevState,
                    key: e
                };
            });
        } catch (error) {
            showBoundary(error)
        }

    };

    return (
        <section className="demo guideAnalytics width100" ref={componentRef}>
            {/* Breadcrumb */}
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <SectionHeader>
                        <li className="active">
                            {insightsLbls.guidesTab}
                        </li>
                    </SectionHeader>
                </div>

                <SectionFilters>
                    <EnvironmentFilter component={"guideAnalytics"} disabled={false} />
                    <Segments component={"guideAnalytics"}></Segments>
                    <EventSource component={"guideAnalytics"}></EventSource>
                    <StateFilter component={"guideAnalytics"} />
                    <Datepicker source="component"></Datepicker>
                </SectionFilters>
            </div>

            {/* <SectionButtons sectionTitle={insightsLbls.guidesTab} svgImage={"guides"} className={'marginTop-50'}>
                <ExportToPdf
                    componentRef={componentRef}
                    source={"Guides"}
                    widthDividedBy={12}
                    disabled={loaderBtn.disabled}
                ></ExportToPdf>
            </SectionButtons> */}

            <div className="marginTop-60 exportBtn-div">
                <Dropdown as={ButtonGroup}>
                    <Button variant="info">{insightsLbls.export}</Button>
                    <Dropdown.Toggle split variant="success" id="dropdown-custom-2" />
                    <Dropdown.Menu className="super-colors">
                        <Dropdown.Item eventKey="1" >
                            <ExportToPdf
                                componentRef={componentRef}
                                source={"Guides"}
                                widthDividedBy={12}
                                disabled={loaderBtn.disabled}
                            ></ExportToPdf>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
            </div>

            <UserInfoSection
                startDate={fetched_details?.commonComponentData?.datesData.fdate}
                endDate={fetched_details?.commonComponentData?.datesData.tdate}
                dateObject={fetched_details?.commonComponentData?.datesData}
                messageText={"Guides"}>
            </UserInfoSection>

            <div id="guideAnalyticsSection">
                {


                    // <SectionRefresh sectionTitle={insightsLbls.guideMe} refreshclassName={"marginTop-30"}>
                    //     <i className="fa fa-refresh pull-right pointer row displayFlex"
                    //         aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(0)}></i>
                    // </SectionRefresh>

                }

                <div className="col-lg-12 page_section  justifyCenter height535 displayFlex paddingLeft-0 paddingRight-0">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={" marginTop-0 section-wrapper icon-text chart-wrapper5 height100-percent"}
                        div3Classes={"col-sm-12 padding0 row marginLR-0 card marginTop-0"}>
                        <div className="col-sm-12 col-md-12 col-lg-12 displayBlock height50 marginTop-20">
                            <span className="paddingLeft-10"><strong>{insightsLbls.guideMe}</strong></span>
                            <span className="pull-right">
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={guideAnalyticsGuideMeChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LinePieChart"}
                                    sectionHeader={insightsLbls.guideMe}
                                    sectionTooltip={
                                        [
                                            {
                                                "subTitle": insightsLbls.activity,
                                                "tooltipText": insightsLbls.graphActivitiesOnLiveGuide,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.totalPlayed + ":",
                                                        "value": insightsLbls.totalNumberOfGuidesPlayed,
                                                    },
                                                    {
                                                        "key": insightsLbls.uniquePlayed + ":",
                                                        "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                    },
                                                    {
                                                        "key": insightsLbls.completed + ":",
                                                        "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                    }
                                                ]
                                            },
                                            {
                                                "subTitle": insightsLbls.completionRatio,
                                                "tooltipText": insightsLbls.guidesVsCompletion1,
                                                "childern": []
                                            }
                                        ]
                                    }
                                    sectionType={'type2'}
                                    guide_filter={true}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={false}
                                    component={'guides'}
                                ></CustomDashboardModal>

                            </span>
                        </div>

                        <div className="col-sm-12 col-md-12 col-lg-12 charts-container">
                            <div className="col-sm-6 paddingTop-20 overflowX chartdiv search_section minHeight490 charts-div-left">
                                <ContainerTooltip div1Classes={"paddingLeft-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.activity,
                                            "tooltipText": insightsLbls.graphActivitiesOnLiveGuide,
                                            "childern": [
                                                {
                                                    "key": insightsLbls.totalPlayed + ":",
                                                    "value": insightsLbls.totalNumberOfGuidesPlayed,

                                                },
                                                {
                                                    "key": insightsLbls.uniquePlayed + ":",
                                                    "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                },
                                                {
                                                    "key": insightsLbls.completed + ":",
                                                    "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                }
                                            ]
                                        }
                                    } placement="right"></SectionTooltip>


                                </ContainerTooltip>
                                {guideAnalyticsGuideMeChart.guideAnalyticsGuideMeChartDataFetched ? (
                                    errorCount.errorCount[0] === 0 ? (
                                        guideAnalyticsGuideMeChart.loadGuideMeChartData.length === 0 ? (
                                            <div className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        ) : (
                                            guideAnalyticsGuideMeChart.guideMeChartFetched ? <>{guideAnalyticsGuideMeChart.guideMeChart}</> :
                                                (
                                                    <>
                                                        <LineChartGuideMe></LineChartGuideMe>
                                                    </>
                                                )
                                        )
                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent minHeight401">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className="col-sm-6 paddingTop-20 overflowX chartdiv search_section minHeight490 charts-div-right">
                                <CardContainer div1Classes={"col-md-12 col-xs-12 "}
                                    div2Classes={" marginTop-0 icon-text borderTop height100-percent"}
                                    div3Classes={"minHeight307 height100-percent"}>

                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.completionRatio,
                                                "tooltipText": insightsLbls.guidesVsCompletion1,
                                                "childern": []
                                            }
                                        } placement="left"></SectionTooltip>
                                    </ContainerTooltip>

                                    {guideAnalyticsDetailGuideMeRadialChart.guideAnalyticsGuideMeRadialChartDataFetched ? (
                                        errorCount.errorCount[0] === 0 ? (
                                            guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData.length === 0 ? (
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            ) : (

                                                guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChartFetched ?
                                                    (
                                                        <React.Fragment>
                                                            <>{guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChart}</>
                                                            <div className="col-md-12">
                                                                <h5 className="font16 textCenter">{guideAnalyticsGuideMeChart.ratioChartText}</h5>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                    :
                                                    (
                                                        <React.Fragment>
                                                            <RadialChartGuideMe></RadialChartGuideMe>
                                                            <div className="col-md-12">
                                                                <h5 className="font16">{guideAnalyticsGuideMeChart.ratioChartText}</h5>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                            )
                                        ) : (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent minHeight270">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader width={100}></Loader>
                                            </div>
                                        </div>
                                    )}
                                </CardContainer>
                                <CardContainer div1Classes={"col-md-12 col-xs-12 padding0"}
                                    div2Classes={" marginTop-0 icon-text borderTop height100-percent borderBottomRight marginLeft--13 marginRight--13 guide-play-info"}
                                    div3Classes={"minHeight130 height100-percent row marginLR-0 guide-play-div"}>
                                    <div className="col-sm-6 padding0 minHeight130 overflowX chartdiv search_section positionRelative guidePlay-left">
                                        {guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChartFetched ? (
                                            errorCount.errorCount[0] === 0 ? (
                                                guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData.length === 0 ? (
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                ) : (
                                                    <div className="guide-count marginTop-10">
                                                        <h3 className="count countHeight payCount-text textCenter">{guideAnalyticsDetailGuideMeRadialChart.totalGuideMeCount}</h3>
                                                        <h5 className="countHeight font13 textCenter">{insightsLbls.playedCount}</h5>
                                                    </div>
                                                )
                                            ) : (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {insightsLbls.serviceNotAvailable}
                                                </h5>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent minHeight130">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader width={50}></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-sm-6 padding0 minHeight130 overflowX chartdiv search_section borderBottomRight positionRelative guidePlay-right">
                                        {guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChartFetched ? (
                                            errorCount.errorCount[0] === 0 ? (
                                                guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData.length === 0 ? (
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                ) : (
                                                    <div className="guide-count marginTop-10">
                                                        <h3 className="count countHeight completeCount-text textCenter">{guideAnalyticsDetailGuideMeRadialChart.totalGuideMeCompleteCount}</h3>
                                                        <h5 className="countHeight font13 textCenter">{insightsLbls.completedCount}</h5>
                                                    </div>
                                                )
                                            ) : (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {insightsLbls.serviceNotAvailable}
                                                </h5>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent minHeight130">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader width={50}></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CardContainer>


                            </div>
                        </div>


                    </CardContainer>
                    {/* <CardContainer div1Classes={"col-md-6 col-xs-6 table-container padding0 marginLeft-10"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 minHeight456 height100-percent padding0 row marginLR-0"}>


                        <div className="col-sm-12 paddingTop-20 overflowX chartdiv search_section ">
                            <CardContainer div1Classes={"col-md-12 col-xs-12 "}
                                div2Classes={" marginTop-0 icon-text borderTop height100-percent"}
                                div3Classes={"minHeight307 height100-percent"}>

                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    <SectionTooltip tooltipText={
                                        {
                                            "subTitle": insightsLbls.completionRatio,
                                            "tooltipText": insightsLbls.guidesVsCompletion1,
                                            "childern": []
                                        }
                                    } placement="left"></SectionTooltip>


                                    <span>
                                        <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(0, 'radialChart')}> {insightsLbls.refresh}</button>
                                        <CustomDashboardModal
                                            api={guideAnalyticsGuideMeChart.api}
                                            eventSource={filterValues.eventSource}
                                            guideStatus={filterValues.guideStatus}
                                            segmentsGroup={filterValues.segmentsGroup}
                                            chartType={"LinePieChart"}
                                            sectionHeader={insightsLbls.guideMe}
                                            sectionTooltip={
                                                [
                                                    {
                                                        "subTitle": insightsLbls.completionRatio,
                                                        "tooltipText": insightsLbls.guidesVsCompletion1,
                                                        "childern": []
                                                    }
                                                ]
                                            }
                                            sectionType={'type2'}
                                            guide_filter={true}
                                            event_filter={true}
                                            segment_filter={true}
                                            tooltip_filter={false}
                                            component={'guides'}
                                        ></CustomDashboardModal>
                                    </span>

                                </ContainerTooltip>



                                {guideAnalyticsDetailGuideMeRadialChart.guideAnalyticsGuideMeRadialChartDataFetched ? (
                                    errorCount.errorCount[0] === 0 ? (
                                        guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData.length === 0 ? (
                                            <div className="noDataText-font textCenter-absolute">
                                                {insightsLbls.noRecordFound}
                                            </div>
                                        ) : (

                                            guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChartFetched ?
                                                (
                                                    <React.Fragment>
                                                        <>{guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChart}</>
                                                        <div className="col-md-12">
                                                            <h5 className="font16 textCenter">{guideAnalyticsGuideMeChart.ratioChartText}</h5>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                                :
                                                (
                                                    <React.Fragment>
                                                        <RadialChartGuideMe></RadialChartGuideMe>
                                                        <div className="col-md-12">
                                                            <h5 className="font16">{guideAnalyticsGuideMeChart.ratioChartText}</h5>
                                                        </div>
                                                    </React.Fragment>
                                                )
                                        )
                                    ) : (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                ) : (
                                    <div className="loaderHeight width100 displayFlex height100-percent minHeight270">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader width={100}></Loader>
                                        </div>
                                    </div>
                                )}
                            </CardContainer>
                            <CardContainer div1Classes={"col-md-12 col-xs-12 padding0"}
                                div2Classes={" marginTop-0 icon-text borderTop height100-percent borderBottomRight marginLeft--13 marginRight--13 guide-play-info"}
                                div3Classes={"minHeight130 height100-percent row marginLR-0 guide-play-div"}>
                                <div className="col-sm-6 padding0 minHeight130 overflowX chartdiv search_section positionRelative guidePlay-left">
                                    {guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChartFetched ? (
                                        errorCount.errorCount[0] === 0 ? (
                                            guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData.length === 0 ? (
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            ) : (
                                                <div className="guide-count marginTop-10">
                                                    <h3 className="count countHeight payCount-text textCenter">{guideAnalyticsDetailGuideMeRadialChart.totalGuideMeCount}</h3>
                                                    <h5 className="countHeight font13 textCenter">{insightsLbls.playedCount}</h5>
                                                </div>
                                            )
                                        ) : (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent minHeight130">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader width={50}></Loader>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="col-sm-6 padding0 minHeight130 overflowX chartdiv search_section borderBottomRight positionRelative guidePlay-right">
                                    {guideAnalyticsDetailGuideMeRadialChart.guideMeRadialChartFetched ? (
                                        errorCount.errorCount[0] === 0 ? (
                                            guideAnalyticsDetailGuideMeRadialChart.loadGuideMeRadialChartData.length === 0 ? (
                                                <div className="noDataText-font textCenter-absolute">
                                                    {insightsLbls.noRecordFound}
                                                </div>
                                            ) : (
                                                <div className="guide-count marginTop-10">
                                                    <h3 className="count countHeight completeCount-text textCenter">{guideAnalyticsDetailGuideMeRadialChart.totalGuideMeCompleteCount}</h3>
                                                    <h5 className="countHeight font13 textCenter">{insightsLbls.completedCount}</h5>
                                                </div>
                                            )
                                        ) : (
                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                {insightsLbls.serviceNotAvailable}
                                            </h5>
                                        )
                                    ) : (
                                        <div className="loaderHeight width100 displayFlex height100-percent minHeight130">
                                            <div className="displayFlex alignCenter margin-0-auto">
                                                <Loader width={50}></Loader>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </CardContainer>


                        </div>
                    </CardContainer> */}
                </div>

                {process.env.REACT_APP_WESTPAC === 'false'
                    &&
                    (
                        <>
                            {/* {

                                <SectionRefresh sectionTitle={insightsLbls.showMe} refreshClass={"marginTop-80"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(1)}>
                                    </i>
                                    <CustomDashboardModal
                                        api={guideAnalyticsShowMeChart.api}
                                        eventSource={filterValues.eventSource}
                                        guideStatus={filterValues.guideStatus}
                                        segmentsGroup={filterValues.segmentsGroup}
                                        chartType={"LinePieDonutChart"}
                                        sectionHeader={insightsLbls.showMe}
                                        sectionTooltip={[
                                            {
                                                "subTitle": insightsLbls.playsByMedia,
                                                "tooltipText": insightsLbls.donutChartShowMeDistribution,
                                                "childern": []
                                            },
                                            {
                                                "subTitle": insightsLbls.playsByTime,
                                                "tooltipText": insightsLbls.graphActivitiesOnShowMe,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.showMe + ":",
                                                        "value": insightsLbls.slideshowPlayedInGivenTimeFrame
                                                    },
                                                    {
                                                        "key": insightsLbls.videos + ":",
                                                        "value": insightsLbls.totalVideosPlayedInGivenTimeFrame
                                                    },
                                                    {
                                                        "key": insightsLbls.gifs + ":",
                                                        "value": insightsLbls.totalGIFdPlayedInGivenTimeFrame
                                                    }
                                                ]
                                            },
                                            {
                                                "subTitle": insightsLbls.downloads + ":",
                                                "tooltipText": insightsLbls.pieActivityPercentageDistribution,
                                                "childern": []
                                            }
                                        ]}
                                        sectionType={'type3'}
                                        guide_filter={true}
                                        event_filter={true}
                                        segment_filter={true}
                                        tooltip_filter={false}
                                        component={'guides'}
                                    ></CustomDashboardModal>
                                </SectionRefresh>

                            } */}

                            <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant showMe-section">
                                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                    div3Classes={"col-sm-12 minHeight400 height100-percent padding0 row marginLR-0"}>

                                    <div className="col-sm-12 col-md-12 col-lg-12 displayBlock height50">
                                        <span className="paddingLeft-10 lineHeight3"><strong>{insightsLbls.showMe}</strong></span>
                                        <span className="pull-right"><button className="pointer refreshBtn marginRight-15 marginTop-20" onClick={() => refreshSections(1)}> {insightsLbls.refresh}</button>
                                            <CustomDashboardModal
                                                api={guideAnalyticsShowMeChart.api}
                                                eventSource={filterValues.eventSource}
                                                guideStatus={filterValues.guideStatus}
                                                segmentsGroup={filterValues.segmentsGroup}
                                                chartType={"LinePieDonutChart"}
                                                sectionHeader={insightsLbls.showMe}
                                                sectionTooltip={[
                                                    {
                                                        "subTitle": insightsLbls.playsByMedia,
                                                        "tooltipText": insightsLbls.donutChartShowMeDistribution,
                                                        "childern": []
                                                    },
                                                    {
                                                        "subTitle": insightsLbls.playsByTime,
                                                        "tooltipText": insightsLbls.graphActivitiesOnShowMe,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.showMe + ":",
                                                                "value": insightsLbls.totalShowMePlayedInGivenTimeFrame
                                                            },
                                                            {
                                                                "key": insightsLbls.slideShow + ":",
                                                                "value": insightsLbls.slideshowPlayedInGivenTimeFrame
                                                            },
                                                            {
                                                                "key": insightsLbls.videos + ":",
                                                                "value": insightsLbls.totalVideosPlayedInGivenTimeFrame
                                                            },
                                                            {
                                                                "key": insightsLbls.gifs + ":",
                                                                "value": insightsLbls.totalGIFdPlayedInGivenTimeFrame
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        "subTitle": insightsLbls.downloads + ":",
                                                        "tooltipText": insightsLbls.pieActivityPercentageDistribution,
                                                        "childern": []
                                                    }
                                                ]}
                                                sectionType={'type3'}
                                                guide_filter={true}
                                                event_filter={true}
                                                segment_filter={true}
                                                tooltip_filter={false}
                                                component={'guides'}
                                            ></CustomDashboardModal>

                                        </span>
                                    </div>


                                    <div className="col-sm-12 col-md-12 col-lg-12 showMe-charts-container">
                                        <div className="col-sm-4 col-md-4 col-lg-4 padding0 paddingLeft-15 paddingTop-20 minHeight350 sectionBorder">
                                            <ContainerTooltip div1Classes={"paddingLeft-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={
                                                    {
                                                        "subTitle": insightsLbls.playsByMedia,
                                                        "tooltipText": insightsLbls.donutChartShowMeDistribution,
                                                        "childern": []
                                                    }
                                                } placement="right"></SectionTooltip>
                                            </ContainerTooltip>
                                            <div className="col-sm-12 padding0 marginTop-20 overflowX chartdiv search_section minHeight350 displayFlex">

                                                {guideAnalyticsShowMeChart.guideAnalyticsShowMeChartDataFetched ? (
                                                    errorCount.errorCount[1] === 0 ? (
                                                        !guideAnalyticsShowMeChart.showMeDonutDataAvailable ? (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            guideAnalyticsShowMeChart.showMeDonutChartFetched ? <>{guideAnalyticsShowMeChart.showDonutChart}</> :
                                                                (<DonutChartShowMe></DonutChartShowMe>)
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {insightsLbls.serviceNotAvailable}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-sm-4 col-md-4 col-lg-4 padding0  paddingTop-20 section-wrapper-sides minHeight350 sectionBorder">
                                            <ContainerTooltip div1Classes={"paddingLeft-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={
                                                    {
                                                        "subTitle": insightsLbls.playsByTime,
                                                        "tooltipText": insightsLbls.graphActivitiesOnShowMe,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.showMe + ":",
                                                                "value": insightsLbls.totalShowMePlayedInGivenTimeFrame
                                                            },
                                                            {
                                                                "key": insightsLbls.slideshow + ":",
                                                                "value": insightsLbls.slideshowPlayedInGivenTimeFrame
                                                            },
                                                            {
                                                                "key": insightsLbls.videos + ":",
                                                                "value": insightsLbls.totalVideosPlayedInGivenTimeFrame
                                                            },
                                                            {
                                                                "key": insightsLbls.gifs + ":",
                                                                "value": insightsLbls.totalGIFdPlayedInGivenTimeFrame
                                                            }
                                                        ]
                                                    }
                                                } placement="right"></SectionTooltip>
                                            </ContainerTooltip>
                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section padding0">
                                                {guideAnalyticsShowMeChart.guideAnalyticsShowMeChartDataFetched ? (
                                                    errorCount.errorCount[1] === 0 ? (
                                                        guideAnalyticsShowMeChart.loadShowMeChartData.length === 0 ? (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight400">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="custom-tabs-container">
                                                                <Tabs
                                                                    activeKey={showMeTabKey.key}
                                                                    onSelect={handleShowMeSwitch}
                                                                    id="showMe-tabs">
                                                                    <Tab eventKey={0} title={insightsLbls.showMe}>
                                                                        <LineChart
                                                                            chartEntities={['guided_showme_play', 'guided_showme_complete']}
                                                                            optionEntities={
                                                                                [
                                                                                    {
                                                                                        key: insightsLbls.play,
                                                                                        color: ChartColors.color1,
                                                                                        associatedDataPoint: "guided_showme_play",
                                                                                        area: true
                                                                                    },
                                                                                    {
                                                                                        key: insightsLbls.completed,
                                                                                        color: ChartColors.color2,
                                                                                        associatedDataPoint: "guided_showme_complete",
                                                                                        area: true
                                                                                    }
                                                                                ]}
                                                                            chartData={guideAnalyticsShowMeChart.loadShowMeChartData}
                                                                            value_type={"count"}
                                                                            height={300}
                                                                            margin={{
                                                                                top: "70",
                                                                                right: "50",
                                                                                bottom: "20",
                                                                                left: "40"
                                                                            }}
                                                                            width={350}
                                                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                            refId="guideAnalytics_showme_gif">
                                                                        </LineChart>
                                                                    </Tab>
                                                                    <Tab eventKey={1} title={insightsLbls.slideshow}>
                                                                        <LineChart
                                                                            chartEntities={['guide_slideshow_play', 'guide_slideshow_complete']}
                                                                            optionEntities={
                                                                                [
                                                                                    {
                                                                                        key: insightsLbls.play,
                                                                                        color: ChartColors.color1,
                                                                                        associatedDataPoint: "guide_slideshow_play",
                                                                                        area: true
                                                                                    },
                                                                                    {
                                                                                        key: insightsLbls.completed,
                                                                                        color: ChartColors.color2,
                                                                                        associatedDataPoint: "guide_slideshow_complete",
                                                                                        area: true
                                                                                    }
                                                                                ]}
                                                                            chartData={guideAnalyticsShowMeChart.loadShowMeChartData}
                                                                            value_type={"count"}
                                                                            height={300}
                                                                            margin={{
                                                                                top: "70",
                                                                                right: "50",
                                                                                bottom: "20",
                                                                                left: "40"
                                                                            }}
                                                                            width={350}
                                                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                            refId="guideAnalytics_showme_slideshow">
                                                                        </LineChart>
                                                                    </Tab>
                                                                    <Tab eventKey={2} title={insightsLbls.video}>
                                                                        <LineChart
                                                                            chartEntities={['guide_video_play', 'guide_video_complete']}
                                                                            optionEntities={
                                                                                [
                                                                                    {
                                                                                        key: insightsLbls.play,
                                                                                        color: ChartColors.color1,
                                                                                        associatedDataPoint: "guide_video_play",
                                                                                        area: true
                                                                                    },
                                                                                    {
                                                                                        key: insightsLbls.completed,
                                                                                        color: ChartColors.color2,
                                                                                        associatedDataPoint: "guide_video_complete",
                                                                                        area: true
                                                                                    }
                                                                                ]}
                                                                            chartData={guideAnalyticsShowMeChart.loadShowMeChartData}
                                                                            value_type={"count"}
                                                                            height={300}
                                                                            margin={{
                                                                                top: "70",
                                                                                right: "50",
                                                                                bottom: "20",
                                                                                left: "40"
                                                                            }}
                                                                            width={350}
                                                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                            refId="guideAnalytics_showme_video">
                                                                        </LineChart>
                                                                    </Tab>
                                                                    <Tab eventKey={3} title={insightsLbls.gif}>
                                                                        <LineChart
                                                                            chartEntities={['guide_gif_play']}
                                                                            optionEntities={
                                                                                [
                                                                                    {
                                                                                        key: insightsLbls.play,
                                                                                        color: ChartColors.color1,
                                                                                        associatedDataPoint: "guide_gif_play",
                                                                                        area: true
                                                                                    }
                                                                                ]}
                                                                            chartData={guideAnalyticsShowMeChart.loadShowMeChartData}
                                                                            value_type={"count"}
                                                                            height={300}
                                                                            margin={{
                                                                                top: "70",
                                                                                right: "50",
                                                                                bottom: "20",
                                                                                left: "40"
                                                                            }}
                                                                            width={350}
                                                                            yAxisLabel={insightsLbls.yAxisLabelCount}
                                                                            refId="guideAnalytics_showme_gif">
                                                                        </LineChart>
                                                                    </Tab>
                                                                </Tabs>
                                                            </div>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {insightsLbls.serviceNotAvailable}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>


                                        </div>

                                        <div className="col-sm-4 col-md-4 col-lg-4 padding0 paddingTop-20 minHeight350 sectionBorder">
                                            <ContainerTooltip div1Classes={"paddingLeft-15"} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                                <SectionTooltip tooltipText={
                                                    {
                                                        "subTitle": insightsLbls.downloads + ":",
                                                        "tooltipText": insightsLbls.pieActivityPercentageDistribution,
                                                        "childern": []
                                                    }
                                                } placement="left"></SectionTooltip>
                                            </ContainerTooltip>
                                            <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section minHeight350 displayFlex">

                                                {guideAnalyticsShowMeChart.guideAnalyticsShowMeChartDataFetched ? (
                                                    errorCount.errorCount[1] === 0 ? (
                                                        !guideAnalyticsShowMeChart.showMePieDataAvailable ? (
                                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                                <div className="displayFlex alignCenter margin-0-auto">
                                                                    <div className="noDataText-font textCenter-absolute">
                                                                        {insightsLbls.noRecordFound}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            guideAnalyticsShowMeChart.showMePieChartFetched ? <>{guideAnalyticsShowMeChart.showPieChart}</> :
                                                                (<PieChartShowMe></PieChartShowMe>)
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                    {insightsLbls.serviceNotAvailable}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight350">
                                                        <div className="displayFlex alignCenter margin-0-auto">
                                                            <Loader></Loader>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                    </div>



                                </CardContainer>
                            </div>
                        </>
                    )}


                {process.env.REACT_APP_WESTPAC === 'false'
                    && (
                        <>
                            {/* {

                                <SectionRefresh sectionTitle={insightsLbls.testMe} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex"
                                        aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(2)}>
                                    </i>

                                    <CustomDashboardModal
                                        api={guideAnalyticsTestMeChart.api}
                                        eventSource={filterValues.eventSource}
                                        guideStatus={filterValues.guideStatus}
                                        segmentsGroup={filterValues.segmentsGroup}
                                        chartType={"BarChart"}
                                        sectionHeader={insightsLbls.testMe}
                                        sectionTooltip={
                                            {
                                                "subTitle": insightsLbls.activity,
                                                "tooltipText": insightsLbls.barGraphTestMe,
                                                "childern": []
                                            }
                                        }
                                        sectionType={'type1'}
                                        guide_filter={true}
                                        event_filter={true}
                                        segment_filter={true}
                                        tooltip_filter={false}
                                        component={'guides'}
                                    ></CustomDashboardModal>
                                </SectionRefresh>

                            } */}
                            <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                    div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.testMe,
                                                "tooltipText": insightsLbls.barGraphTestMe,
                                                "childern": []
                                            }
                                        } placement="right"></SectionTooltip>

                                        <span>
                                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(2)}> {insightsLbls.refresh}</button>
                                            <CustomDashboardModal
                                                api={guideAnalyticsTestMeChart.api}
                                                eventSource={filterValues.eventSource}
                                                guideStatus={filterValues.guideStatus}
                                                segmentsGroup={filterValues.segmentsGroup}
                                                chartType={"BarChart"}
                                                sectionHeader={insightsLbls.testMe}
                                                sectionTooltip={
                                                    {
                                                        "subTitle": insightsLbls.activity,
                                                        "tooltipText": insightsLbls.barGraphTestMe,
                                                        "childern": []
                                                    }
                                                }
                                                sectionType={'type1'}
                                                guide_filter={true}
                                                event_filter={true}
                                                segment_filter={true}
                                                tooltip_filter={false}
                                                component={'guides'}
                                            ></CustomDashboardModal>
                                        </span>
                                    </ContainerTooltip>
                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                        {guideAnalyticsTestMeChart.guideAnalyticsTestMeChartDataFetched ? (
                                            errorCount.errorCount[2] === 0 ? (
                                                guideAnalyticsTestMeChart.loadTestMeChartData.length === 0 ? (
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                ) : (

                                                    guideAnalyticsTestMeChart.testMeChartFetched ? <>{guideAnalyticsTestMeChart.testMeChart}</> :
                                                        (<BarChartTestMe></BarChartTestMe>)
                                                )
                                            ) : (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {insightsLbls.serviceNotAvailable}
                                                </h5>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CardContainer>
                            </div>
                        </>
                    )}

                {process.env.REACT_APP_WESTPAC === 'false'
                    && (
                        <>
                            {/* {

                                <SectionRefresh sectionTitle={insightsLbls.doItForMe} refreshClass={"marginTop-30"}>
                                    <i className="fa fa-refresh pull-right pointer row displayFlex"
                                        aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(3)}>
                                    </i>

                                    <CustomDashboardModal
                                        api={guideAnalyticsDoItForMeChart.api}
                                        eventSource={filterValues.eventSource}
                                        guideStatus={filterValues.guideStatus}
                                        segmentsGroup={filterValues.segmentsGroup}
                                        chartType={"LineChart"}
                                        sectionHeader={insightsLbls.doItForMe}
                                        sectionTooltip={
                                            {
                                                "subTitle": insightsLbls.activity,
                                                "tooltipText": insightsLbls.guidePlayedVieDoItForMe,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.totalPlayed + ":",
                                                        "value": insightsLbls.totalNumberOfGuidesPlayed
                                                    },
                                                    {
                                                        "key": insightsLbls.uniquePlayed + ":",
                                                        "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                    },
                                                    {
                                                        "key": insightsLbls.completed + ":",
                                                        "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                    }
                                                ]
                                            }
                                        }
                                        sectionType={'type1'}
                                        guide_filter={true}
                                        event_filter={true}
                                        segment_filter={true}
                                        tooltip_filter={false}
                                        component={'guides'}
                                    ></CustomDashboardModal>
                                </SectionRefresh>

                            } */}
                            <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                                <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                    div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                    div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                    <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.doItForMe,
                                                "tooltipText": insightsLbls.guidePlayedVieDoItForMe,
                                                "childern": [
                                                    {
                                                        "key": insightsLbls.totalPlayed + ":",
                                                        "value": insightsLbls.totalNumberOfGuidesPlayed
                                                    },
                                                    {
                                                        "key": insightsLbls.uniquePlayed + ":",
                                                        "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                    },
                                                    {
                                                        "key": insightsLbls.completed + ":",
                                                        "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                    }
                                                ]
                                            }
                                        } placement="right"></SectionTooltip>

                                        <span>
                                            <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(3)}> {insightsLbls.refresh}</button>
                                            <CustomDashboardModal
                                                api={guideAnalyticsDoItForMeChart.api}
                                                eventSource={filterValues.eventSource}
                                                guideStatus={filterValues.guideStatus}
                                                segmentsGroup={filterValues.segmentsGroup}
                                                chartType={"LineChart"}
                                                sectionHeader={insightsLbls.doItForMe}
                                                sectionTooltip={
                                                    {
                                                        "subTitle": insightsLbls.activity,
                                                        "tooltipText": insightsLbls.guidePlayedVieDoItForMe,
                                                        "childern": [
                                                            {
                                                                "key": insightsLbls.totalPlayed + ":",
                                                                "value": insightsLbls.totalNumberOfGuidesPlayed
                                                            },
                                                            {
                                                                "key": insightsLbls.uniquePlayed + ":",
                                                                "value": insightsLbls.totalNumberOfUniqueGuidesPlayed
                                                            },
                                                            {
                                                                "key": insightsLbls.completed + ":",
                                                                "value": insightsLbls.totalNumberOfCompleteGuidesPlayed
                                                            }
                                                        ]
                                                    }
                                                }
                                                sectionType={'type1'}
                                                guide_filter={true}
                                                event_filter={true}
                                                segment_filter={true}
                                                tooltip_filter={false}
                                                component={'guides'}
                                            ></CustomDashboardModal>
                                        </span>
                                    </ContainerTooltip>
                                    <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                        {guideAnalyticsDoItForMeChart.guideAnalyticsDoItForMeChartDataFetched ? (
                                            errorCount.errorCount[3] === 0 ? (
                                                guideAnalyticsDoItForMeChart.loadDoItForMeChartData.length === 0 ? (
                                                    <div className="noDataText-font textCenter-absolute">
                                                        {insightsLbls.noRecordFound}
                                                    </div>
                                                ) : (

                                                    guideAnalyticsDoItForMeChart.doItForMeChartFetched ? <>{guideAnalyticsDoItForMeChart.doItForMeChart}</> :
                                                        (
                                                            <>
                                                                <LineChartDoItForMe></LineChartDoItForMe>
                                                            </>
                                                        )
                                                )
                                            ) : (
                                                <h5 className="errorCss card-heading font-weight-400 text-center">
                                                    {insightsLbls.serviceNotAvailable}
                                                </h5>
                                            )
                                        ) : (
                                            <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                <div className="displayFlex alignCenter margin-0-auto">
                                                    <Loader></Loader>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CardContainer>
                            </div>
                        </>
                    )}
                {/* {
                    <SectionRefresh sectionTitle={insightsLbls.myGuideSurvey} refreshClass={"marginTop-30"}>
                        <i
                            className="fa fa-refresh pull-right pointer row displayFlex"
                            aria-hidden="true" title={insightsLbls.refresh}
                            onClick={() => refreshSections(4)}>
                        </i>
                        <CustomDashboardModal
                            api={guideAnalyticsSurveyChart.api}
                            eventSource={filterValues.eventSource}
                            guideStatus={filterValues.guideStatus}
                            segmentsGroup={filterValues.segmentsGroup}
                            chartType={"LineChart"}
                            sectionHeader={insightsLbls.myGuideSurvey}
                            sectionTooltip={
                                {
                                    "subTitle": insightsLbls.survey,
                                    "tooltipText": insightsLbls.totalNuberOfGuideAndExitResponses,
                                    "childern": []
                                }
                            }
                            sectionType={'type1'}
                            guide_filter={true}
                            event_filter={true}
                            segment_filter={true}
                            tooltip_filter={false}
                            component={'guides'}
                        ></CustomDashboardModal>
                    </SectionRefresh>

                } */}

                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            <SectionTooltip tooltipText={
                                {
                                    "subTitle": insightsLbls.survey,
                                    "tooltipText": insightsLbls.totalNuberOfGuideAndExitResponses,
                                    "childern": []
                                }
                            } placement="right"></SectionTooltip>
                            <span>
                                <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(4)}> {insightsLbls.refresh}</button>
                                <CustomDashboardModal
                                    api={guideAnalyticsSurveyChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.myGuideSurvey}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.survey,
                                            "tooltipText": insightsLbls.totalNuberOfGuideAndExitResponses,
                                            "childern": []
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={true}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={false}
                                    component={'guides'}
                                ></CustomDashboardModal>
                            </span>
                        </ContainerTooltip>

                        <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">

                            {guideAnalyticsSurveyChart.guideAnalyticsSurveyChartDataFetched ? (
                                errorCount.errorCount[4] === 0 ? (
                                    guideAnalyticsSurveyChart.loadSurveyChartData.length === 0 ? (
                                        <div className="noDataText-font textCenter-absolute">
                                            {insightsLbls.noRecordFound}
                                        </div>
                                    ) : (
                                        <>
                                            {fetched_details?.commonComponentData?.datesData.excludeFlag && (
                                                <p className="weekendsIncluded-p">
                                                    <span>{insightsLbls.weekendsIncluded}</span>
                                                </p>
                                            )}

                                            {guideAnalyticsSurveyChart.surveyChartFetched ? (guideAnalyticsSurveyChart.surveyChart) :
                                                (
                                                    <>
                                                        <LineChartSurvey></LineChartSurvey>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                                ) : (
                                    <h5 className="errorCss card-heading font-weight-400 text-center">
                                        {insightsLbls.serviceNotAvailable}
                                    </h5>
                                )
                            ) : (
                                <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                    <div className="displayFlex alignCenter margin-0-auto">
                                        <Loader></Loader>
                                    </div>
                                </div>
                            )}
                        </div>

                    </CardContainer>
                </div>

                {process.env.REACT_APP_WESTPAC === 'false' &&
                    <>
                        {/* {

                            <SectionRefresh sectionTitle={insightsLbls.guidesPlayedByPushAndBeacons} refreshClass={"marginTop-30"}>
                                <i
                                    className="fa fa-refresh pull-right pointer row displayFlex"
                                    aria-hidden="true" title={insightsLbls.refresh}
                                    onClick={() => refreshSections(5)}>
                                </i>

                                <CustomDashboardModal
                                    api={guideAnalyticsPBChart.api}
                                    eventSource={filterValues.eventSource}
                                    guideStatus={filterValues.guideStatus}
                                    segmentsGroup={filterValues.segmentsGroup}
                                    chartType={"LineChart"}
                                    sectionHeader={insightsLbls.guidesPlayedByPushAndBeacons}
                                    sectionTooltip={
                                        {
                                            "subTitle": insightsLbls.displayCount,
                                            "tooltipText": insightsLbls.totalNumbersUsersInteractedWithPNB1,
                                            "childern": []
                                        }
                                    }
                                    sectionType={'type1'}
                                    guide_filter={true}
                                    event_filter={true}
                                    segment_filter={true}
                                    tooltip_filter={false}
                                    component={'guides'}
                                ></CustomDashboardModal>
                            </SectionRefresh>

                        } */}

                        <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                            <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                                div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                                div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                                <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                                    {
                                        <SectionTooltip tooltipText={
                                            {
                                                "subTitle": insightsLbls.guidesPlayedByPushAndBeacons,
                                                "tooltipText": insightsLbls.totalNumbersUsersInteractedWithPNB1,
                                                "childern": []
                                            }
                                        } placement="right"></SectionTooltip>
                                    }

                                    <span>
                                        <button className="pointer refreshBtn marginRight-15" onClick={() => refreshSections(5)}> {insightsLbls.refresh}</button>
                                        <CustomDashboardModal
                                            api={guideAnalyticsPBChart.api}
                                            eventSource={filterValues.eventSource}
                                            guideStatus={filterValues.guideStatus}
                                            segmentsGroup={filterValues.segmentsGroup}
                                            chartType={"LineChart"}
                                            sectionHeader={insightsLbls.guidesPlayedByPushAndBeacons}
                                            sectionTooltip={
                                                {
                                                    "subTitle": insightsLbls.guidesPlayedByPushAndBeacons,
                                                    "tooltipText": insightsLbls.totalNumbersUsersInteractedWithPNB1,
                                                    "childern": []
                                                }
                                            }
                                            sectionType={'type1'}
                                            guide_filter={true}
                                            event_filter={true}
                                            segment_filter={true}
                                            tooltip_filter={false}
                                            component={'guides'}
                                        ></CustomDashboardModal>
                                    </span>
                                </ContainerTooltip>

                                <div className="col-sm-12 marginTop-20 overflowX chartdiv search_section">
                                    <>
                                        <div className="minHeight400">
                                            <Tabs
                                                activeKey={tabKey.key}
                                                onSelect={handleSwitch}
                                                id="controlled-tab-example"
                                            >
                                                <Tab eventKey={1} title={insightsLbls.guideMeAndShowMe}>
                                                    {(guideAnalyticsPBChart.guideAnalyticsPBChartDataFetched) ? (
                                                        errorCount.errorCount[5] === 0 ? (
                                                            guideAnalyticsPBChart.loadPBChartData.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                guideAnalyticsPBChart.pBChartFetched ? <>{guideAnalyticsPBChart.pBChart}</> :
                                                                    (
                                                                        <>
                                                                            <LineChartPB></LineChartPB>
                                                                        </>
                                                                    )
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>


                                                <Tab eventKey={2} title={insightsLbls.doItForMe}>
                                                    {guideAnalyticsDoItForMePBChart.guideAnalyticsDoItForMePBChartDataFetched ? (
                                                        errorCount.errorCount[7] === 0 ? (
                                                            guideAnalyticsDoItForMePBChart.loadDoItForMePBChartData.length === 0 ? (
                                                                <div className="noDataText-font textCenter-absolute">
                                                                    {insightsLbls.noRecordFound}
                                                                </div>
                                                            ) : (
                                                                guideAnalyticsDoItForMePBChart.doItForMePBChartFetched ?
                                                                    <>{guideAnalyticsDoItForMePBChart.doItForMePBChart}</> :
                                                                    (
                                                                        <>
                                                                            <LineChartDoItForMePB></LineChartDoItForMePB>
                                                                        </>
                                                                    )
                                                            )
                                                        ) : (
                                                            <h5 className="errorCss card-heading font-weight-400 text-center">
                                                                {insightsLbls.serviceNotAvailable}
                                                            </h5>
                                                        )
                                                    ) : (
                                                        <div className="loaderHeight width100 displayFlex height100-percent top-30 positionRelative minHeight490">
                                                            <div className="displayFlex alignCenter margin-0-auto">
                                                                <Loader></Loader>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Tab>


                                            </Tabs>
                                        </div>
                                    </>
                                </div>

                            </CardContainer>
                        </div>
                    </>
                }


                {/* {

                    <SectionRefresh sectionTitle={insightsLbls.summary} refreshClass={"marginTop-30"}>
                        <i className="fa fa-refresh pull-right pointer row displayFlex" aria-hidden="true" title={insightsLbls.refresh} onClick={() => refreshSections(7)}></i>
                    </SectionRefresh>
                } */}
                <div className="dashboardTiles borderBottom8 borderTop col-lg-12 page_section padding0 height535 blockImportant marginTop-20">
                    <CardContainer div1Classes={"col-md-12 col-xs-12 table-container padding0"}
                        div2Classes={"card marginTop-0 section-wrapper icon-text chart-wrapper5 borderTop height100-percent"}
                        div3Classes={"col-sm-12 cardPadding minHeight490 height100-percent"}>
                        <ContainerTooltip div1Classes={""} div2Classes={""} hTagClasses={"section-heading headingText"}>
                            {process.env.REACT_APP_WESTPAC === 'false' ? (
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.guideSummary,
                                        "tooltipText": insightsLbls.myGuideGuidesPlayed,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideName + ":",
                                                "value": insightsLbls.nameOfGuidesPlayed
                                            },
                                            {
                                                "key": insightsLbls.totalPlayed + ":",
                                                "value": insightsLbls.totalGuidesPlayedTitle
                                            },
                                            {
                                                "key": insightsLbls.guidePlayed + ":",
                                                "value": insightsLbls.totalGuidesCompleteCount
                                            },
                                            {
                                                "key": insightsLbls.doItForMePlayed + ":",
                                                "value": insightsLbls.totalNumberOfTimesGuidePlayedInDoItForMe
                                            },
                                            {
                                                "key": insightsLbls.showMePlayed + ":",
                                                "value": insightsLbls.totalSlideshowVideoGIFCount
                                            },
                                            {
                                                "key": insightsLbls.testMePlayed + ":",
                                                "value": insightsLbls.totalTestPassCount
                                            },
                                            {
                                                "key": insightsLbls.myGuideSurveysPlayed + ":",
                                                "value": insightsLbls.totalResponsesSubmitted
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            ) : (
                                <SectionTooltip tooltipText={
                                    {
                                        "subTitle": insightsLbls.guideSummary,
                                        "tooltipText": insightsLbls.myGuideGuidesPlayed,
                                        "childern": [
                                            {
                                                "key": insightsLbls.guideName + ":",
                                                "value": insightsLbls.nameOfGuidesPlayed
                                            },
                                            {
                                                "key": insightsLbls.guidePlayed + ":",
                                                "value": insightsLbls.totalGuidesCompleteCount
                                            },
                                            {
                                                "key": insightsLbls.myGuideSurveysPlayed + ":",
                                                "value": insightsLbls.totalResponsesSubmitted
                                            }
                                        ]
                                    }
                                } placement="right"></SectionTooltip>
                            )}

                            <button className="pointer refreshBtn pull-right" onClick={() => refreshSections(7)}> {insightsLbls.refresh}</button>

                        </ContainerTooltip>
                        {
                            guideAnalyticsSummaryTable.guideAnalyticsSummaryTableDataFetched ? (
                                errorCount.errorCount[7] === 0 ? (
                                    <div className="tabelEditing  commonSummaryTable">
                                        <CommonExpandableTable tableId="1"
                                            data={fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData.data}
                                            currentPage={guideAnalyticsSummaryTable.page}
                                            barColors=""
                                            linkPresent={true}
                                            showExport={true}
                                            showSearch={true}
                                            showEntries={true}
                                            defaultSort={guideAnalyticsSummaryTable.defaultSort}
                                            tablePagination={guideAnalyticsSummaryTable.tablePagination}
                                            tableEvent={handleTableEvents}
                                            firstTimeloadFlag={fetched_details?.guideAnalytics?.guideAnalyticsSummaryTableData.firstTimeload}
                                            hideDeleted={true}
                                            checkboxText={insightsLbls.showGuideWithActivities}
                                            modalTable={false}
                                            source={"guideAnalytics"}
                                        />
                                    </div>
                                )
                                    : errorCount.errorCount[6] === 1 ? (
                                        <h5 className="errorCss card-heading font-weight-400 text-center">
                                            {insightsLbls.serviceNotAvailable}
                                        </h5>
                                    )
                                        : null)
                                : (
                                    <div className="loaderHeight width100 displayFlex minHeight430 top-30 positionRelative">
                                        <div className="displayFlex alignCenter margin-0-auto">
                                            <Loader></Loader>
                                        </div>
                                    </div>
                                )
                        }
                    </CardContainer>
                </div>

            </div>

            <div className="overlayCursor" id="overlayCursor"></div>
            {errorCount.showMaintenance_flag && (
                <Maintenance></Maintenance>
            )}
        </section>

    )
}
export default withRouter(withReducer("guideAnalytics", guideAnalyticsReducer)(GuideAnalytics));