/*
    @author Mohit Tiwari
    Last Updated on 11 June 2024
    Description: a) Set initial load app selection limit as 3, post that user can select up to 10.
                 b) Withdraw state of app selection on close of modal without applying, by adding one temporary app selection state
*/

import axios from "axios";
import CommonModal from "components/CommonModal/CommonModal";
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { LanguageState, OrgState, VisibilityState } from "store/Reducers"

import { getCall } from "utils/ApiCallActions";
import { RetryApi } from "utils/GlobalConstants";
import "./AppEnvListDropdown.scss";
import { Dropdown } from "react-bootstrap";
import Loader from "components/Loader/Loader";
import { InsightLabels } from "labels";
import { useErrorBoundary } from 'react-error-boundary';


interface CurrentState {
    languageData: LanguageState,
    sectionVisibility: VisibilityState,
    org: OrgState
}

const AppEnvListDropdown = (props: any) => {

    const { showBoundary } = useErrorBoundary();

    /* ---------- Constants ---------- */
    // const total_appCount = 10;
    const [total_appCount, setTotalAppCount] = useState(3);
    /* ---------- State Initialization ---------- */
    const AppEnvListMount = useRef(false);
    //To set labels
    const [insightsLbls, setInsightsLbls] = useState<InsightLabels>({} as InsightLabels);
    //To check whether data is fetched, used to show loader
    const [apiFetched, setApiFetch] = useState(false);
    //Map app id with app name, used to show app list
    const [appMapper, setAppMapper] = useState(new Map());
    //Map app id with app code
    const [appCodeMapper, setAppCodeMapper] = useState(new Map());
    //To store deleted apps
    const [deletedApps, setDeletedApps] = useState([]);
    //Map app id with environments of that app
    const [appEnvList, setAppEnvList] = useState(new Map());
    //To handle show & hide of app and environment selection modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    //To handle dropdown open and close of environment filter 
    const [openedEnvSelectDropdownAppId, setopenedEnvSelectDropdownAppId] = useState("");
    //To store the selected environment for the respective app
    const [envSelection, setEnvSelection] = useState(new Map());
    //To store the selected app checked status
    const [appSelection, setAppSelection] = useState(new Map());
    //To create a temporary state of newly selected apps
    const [tempAppSelector, setTempAppSelector] = useState(new Map());
    const [btnState, setBtnState] = useState({
        okBtnState: false
    });


    let fetched_details = useSelector((state: CurrentState) => state);
    let orgDetails = useSelector((state: CurrentState) => state.org);


    /* ---------- UseEffects ---------- */
    //Language
    useEffect(() => {
        if (fetched_details.languageData.languageData) {
            setInsightsLbls(fetched_details.languageData.languageData);
        }

    }, [fetched_details.languageData.languageData])


    // First Load
    useEffect(() => {
        getAppList();
    }, []);


    useEffect(() => {
        try {
            if (orgDetails.orgSwitch) {
                setTotalAppCount(3);
                setApiFetch(false);
                AppEnvListMount.current = false
                getAppList("org_switch", 3);
            }
        } catch (error) {
            showBoundary(error)
        }

    }, [orgDetails])

    // To send data to dashboard first time
    useEffect(() => {
        try {
            if (!AppEnvListMount.current && appCodeMapper.size > 0 && appMapper.size > 0 && appSelection.size > 0 && envSelection.size > 0) {
                applyEnvFilter();
                AppEnvListMount.current = true;
            }
        } catch (error) {
            showBoundary(error)
        }
    }, [appCodeMapper, appMapper, appSelection, envSelection])

    const getAppList = (apiErrorFlag?: string, totalAppCountParam?: number) => {
        try {
            let cancelToken: any;
            if (typeof cancelToken !== typeof undefined) {
                cancelToken.cancel(insightsLbls.tokenCancelledMsg);
            }
            cancelToken = axios.CancelToken.source();

            let params = {
                "type": 'org',
                "sort_by": "app_name",
                "order": "asc",
                "search_text": '',
                "page_index": 1,
                "page_size": 9999,
                "hide_deleted": 0
            };
            /* Fetching existing selected apps, saved in local storage */
            let dashboardAppList: any = localStorage.getItem('DASHBOARD_APP_LIST');
            dashboardAppList = JSON.parse(dashboardAppList);
            /* These mapper are to provide one to one mapping of app code with other attribute, to access data easily */
            let tempAppCheck = new Map();
            let tempAppEnvMap = new Map();
            let tempAppMapper = new Map();
            let tempEnvSelection = new Map();
            let tempAppCodeMapper = new Map();
            let deletedAppList: any = [];
            /* Api fetch is made false, as API is called */
            setApiFetch(false);
            /* Fetching app list */
            let tempTotalAppCount = total_appCount;
            if (totalAppCountParam) {
                tempTotalAppCount = totalAppCountParam;
            }
            getCall(params, 'APP_LIST', apiErrorFlag ?? '').then((data: any) => {
                if (data?.result === 'success') {
                    setApiFetch(true);
                    let totalSelectionCount = data.data.total_count
                    for (let i = 0; i < totalSelectionCount; i++) {
                        let environment_list = data.data.apps[i].environments;
                        let update_env_list = [];
                        /* All is default environment */
                        update_env_list.push({ "environment_code": "", "environment_title": "All" });
                        if (environment_list.length > 0) {
                            update_env_list.push({ "environment_code": "env_legacy", "environment_title": "Legacy" })
                        }
                        update_env_list.push(...environment_list);

                        if (data.data.apps[i].is_archived) {
                            deletedAppList.push(data.data.apps[i].app_id);
                        }
                        tempEnvSelection.set(data.data.apps[i].app_id, { "environment_code": "", "environment_title": "All" });
                        tempAppMapper.set(data.data.apps[i].app_id, data.data.apps[i].app_name);
                        tempAppCodeMapper.set(data.data.apps[i].app_id, data.data.apps[i].app_code);
                        tempAppEnvMap.set(data.data.apps[i].app_id, update_env_list);

                        /**
                         * Currently it's implemented only for dashboard, so no further segregation has been made
                         **/
                        if (dashboardAppList == undefined || dashboardAppList.length < 1) {
                            tempAppCheck.set(data.data.apps[i].app_id, i < tempTotalAppCount);
                        } else {
                            tempAppCheck.set(data.data.apps[i].app_id, false);
                            for (let itr0 = 0; itr0 < dashboardAppList.length; itr0++) {
                                if (data.data.apps[i].app_id == dashboardAppList[itr0].app_id) {
                                    tempAppCheck.set(data.data.apps[i].app_id, true);
                                    if (dashboardAppList[itr0].env_code) {
                                        tempEnvSelection.set(data.data.apps[i].app_id,
                                            { "environment_code": dashboardAppList[itr0].env_code, "environment_title": dashboardAppList[itr0].env_title })
                                    }
                                    break;
                                }
                            }
                        }
                    }
                    setAppMapper(tempAppMapper);
                    setAppEnvList(tempAppEnvMap);
                    setAppSelection(tempAppCheck);
                    setAppCodeMapper(tempAppCodeMapper);
                    setEnvSelection(tempEnvSelection);
                    setDeletedApps(deletedAppList);
                } else if (data.result === 'retry') {
                    setTimeout(() => {
                        getAppList('retry')
                    }, RetryApi.TIMEOUT)
                }
                else if (
                    data?.result === "error"
                ) {
                    setApiFetch(true);
                    //set error message
                }
            });
        } catch (error) {
            showBoundary(error)
        }
    }

    const openAppEnvModal = () => {
        setTotalAppCount(10);
        setIsModalOpen(true);
        setTempAppSelector(new Map());
    }

    const closeAppEnvModal = () => {
        setIsModalOpen(false);
    }


    const toggleEnvDropDown = (key: any, event: boolean, i: number) => {
        try {
            if (event) {
                setopenedEnvSelectDropdownAppId(key);
            } else {
                setopenedEnvSelectDropdownAppId("");
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const selectEnvironment = (key: any, value: any, i: number) => {
        try {
            let tempEnvSelection = deepCopyMap(envSelection);
            tempEnvSelection.set(key, value);
            setEnvSelection(tempEnvSelection);
            toggleEnvDropDown(key, false, i);
        } catch (error) {
            showBoundary(error)
        }
    }

    const selectApp = (key: any, event: any) => {
        try {
            let tempAppSelection = tempAppSelector.size > 0 ? shallowCopyMap(tempAppSelector) : shallowCopyMap(appSelection);
            if (event.target.checked) {
                let firstSelectionId = "";
                let numOfAppSelected = 0;
                tempAppSelection.forEach((value: any, key: any) => {
                    if (value) {
                        if (firstSelectionId == "") {
                            firstSelectionId = key;
                        }
                        numOfAppSelected++;
                    }
                })
                if (numOfAppSelected >= total_appCount) {
                    tempAppSelection.set(firstSelectionId, false);
                }

            }
            tempAppSelection.set(key, event.target.checked);
            // setAppSelection(tempAppSelection);
            setTempAppSelector(tempAppSelection);

            let cnt = 0
            tempAppSelection.forEach((value, key) => {
                if (value === false) {
                    cnt++
                }
            });


            setBtnState((prevState) => {
                return {
                    ...prevState,
                    okBtnState: cnt === tempAppSelector.size
                }
            })



        } catch (error) {
            showBoundary(error)
        }
    }



    const applyEnvFilter = () => {
        try {
            let finalAppList: any[] = [];
            if (tempAppSelector.size > 0) {
                setAppSelection(tempAppSelector);
                tempAppSelector.forEach((value, key) => {
                    if (value) {
                        let appObj: any = { app_id: key, app_name: appMapper.get(key) };
                        if (envSelection.get(key) != undefined && envSelection.get(key)["environment_code"] != "") {
                            appObj["app_code"] = appCodeMapper.get(key);
                            appObj["env_code"] = envSelection.get(key)["environment_code"];
                            appObj["env_title"] = envSelection.get(key)["environment_title"];
                        }
                        finalAppList.push(appObj);
                    }
                });
            } else {
                appSelection.forEach((value, key) => {
                    if (value) {
                        let appObj: any = { app_id: key, app_name: appMapper.get(key) };
                        if (envSelection.get(key) != undefined && envSelection.get(key)["environment_code"] != "") {
                            appObj["app_code"] = appCodeMapper.get(key);
                            appObj["env_code"] = envSelection.get(key)["environment_code"];
                            appObj["env_title"] = envSelection.get(key)["environment_title"];
                        }
                        finalAppList.push(appObj);
                    }
                });
            }

            props.finalApps(finalAppList);
            if (AppEnvListMount.current) {
                localStorage.setItem("DASHBOARD_APP_LIST", JSON.stringify(finalAppList));
            }
        } catch (error) {
            showBoundary(error)
        }
    }

    const shallowCopyMap = (originalMap: any) => {
        try {
            return new Map(originalMap);
        } catch (error) {
            showBoundary(error)
        }

    }

    const deepCopyMap: any = (originalMap: any) => {
        try {
            return new Map([...originalMap].map(([key, value]) =>
                [key, value instanceof Map ? deepCopyMap(value) : value instanceof Object ? deepCopyObject(value) : value]
            ));
        } catch (error) {
            showBoundary(error)
        }

    }

    const deepCopyObject: any = (originalObject: any) => {
        try {
            return Object.fromEntries(
                Object.entries(originalObject).map(([key, value]) =>
                    [key, value instanceof Map ? deepCopyMap(value) :
                        value instanceof Object ? deepCopyObject(value) : value]
                )
            );
        } catch (error) {
            showBoundary(error)
        }
    }

    return (
        <section className="">
            <div className="appEnv-filter-title">{insightsLbls['appEnvironmentFilter']}</div>
            <div className="app-select-btn" onClick={openAppEnvModal} style={{ right: (fetched_details.sectionVisibility.guideInsights_flag === false) ? '290px' : '198px' }}>
                {/* <span className="app-count">+10</span> */}
                <p>{insightsLbls.appEnvSelect}</p>
            </div>
            <CommonModal modalState={isModalOpen} modalTitle={insightsLbls.appEnvironmentFilter} size={'md'} footerDisabled={false} exportModal={false}
                padding={true} dispatchModalState={closeAppEnvModal} modalDialogClass={""} key="" cancelBtn={insightsLbls['cancel']} okBtn={insightsLbls['apply']}
                dispatchModalOk={() => applyEnvFilter()} dispatchModalCancel={closeAppEnvModal} okBtnState={btnState.okBtnState}>
                <div className="modal-body-container" id="modalBodyContainer">
                    {apiFetched ? Array.from(appMapper.entries()).map(([key, value], i) => {
                        return (
                            <div className="app-row">
                                <div className="app-panel">

                                    <div className="custom-control custom-checkbox mr-sm-2 displayFlex alignCenter  marginLeft-20 marginTop-10 marginBottom-10 check-container padding-chk-0">
                                        <div className="custom-control custom-checkbox mr-sm-2 check-container padding-chk-0 pointer">
                                            <label className="check-container displayFlex alignCenter exportCheck marginBottom-0 marginLeft--5 marginTop-0">
                                                <input type="checkbox" className={"custom-control-input chk checkbox"}
                                                    id={"customControlAutosizing_" + key} name={"app_name_" + key}
                                                    value={key} onChange={(event) => selectApp(key, event)} checked={tempAppSelector.size > 0 ? tempAppSelector.get(key) : appSelection.get(key)}></input>
                                                <span className="checkmark checkmarkBox-color displayInlineBlock top0"></span>
                                                <span className="marginLeft-10">{value}</span>
                                                {deletedApps.includes(key) && <span className="paddingDeleted font12 deletedText">{insightsLbls.deleted}</span>}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="env-panel">
                                    <div className="appEnvListDropdownDashboard">
                                        <Dropdown className="env-select" key={"dropdown_" + key} show={key == openedEnvSelectDropdownAppId} onToggle={(event) => toggleEnvDropDown(key, event, i)}>
                                            <Dropdown.Toggle>
                                                <span>{(envSelection.get(key) != undefined && envSelection.get(key)["environment_title"] != undefined) ? envSelection.get(key)["environment_title"] : "Select Environment"}</span>
                                                <i aria-hidden="true" className={"switchApp-btn-icon " + (openedEnvSelectDropdownAppId == key ? "fa fa-chevron-up" : "fa fa-chevron-down")}></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu flip={true}>
                                                {appEnvList.get(key) != undefined && (appEnvList.get(key) as []).map((env, index) => {
                                                    return <div className="dropdown-status-menu">
                                                        <button onClick={() => selectEnvironment(key, env, i)} id={"status_" + env["env_code"] + "_" + key}
                                                            className={"pointer dropdown-menu-item statusBtn " + (envSelection.get(key) != undefined && envSelection.get(key)["environment_title"] === env["environment_title"] ? "active-button" : "")}>
                                                            {env["environment_title"]}
                                                        </button>
                                                    </div>
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div> */}
                            </div>
                        )
                    }) : <Loader></Loader>}
                </div>
            </CommonModal>
        </section>
    );
}

export default React.memo(AppEnvListDropdown);